import React, {useState} from 'react';
import './style.sass';
import { Link } from 'react-router-dom';

export default function BoxPoliticasSears(){
    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView( !menuView );
    };

    return (
        <div className="boxServicioCliente">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>POLÍTICAS SEARS</p>
            <ul>
                <li><Link to={`/aviso-privacidad/`}>Aviso de Privacidad</Link></li>
                <li><Link to={`/politica-cancelacion/`} >Política de Cancelación</Link></li>
                <li><Link to={`/politica-devolucion/`} >Política de Devoluciones</Link></li>
                <li><Link to={`/politica-envio/`} >Política de Envío</Link></li>
                <li><Link to={`/politica-compra/`} >Política de Compra</Link></li>
                <li><Link to={`/terminos-condiciones-market-place/`} >Políticas Market Place</Link></li>
            </ul>
        </div>
    )
}
;