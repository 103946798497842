import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function TerminosCondicionesDeskop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Terminos y Condiciones');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Términos y Condiciones Paperless</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>Estado de Cuenta Sears</h2>
                                <p className="h3">Al aceptar éstos términos y condiciones usted está de acuerdo en que:</p>

                                <p>El Estado de Cuenta que Sears Operadora México S.A. de C.V emita mensualmente le sea puesto a su disposición en formato electrónico a través del portal de autogestión en Internet: <Link to="/">www.sears.com.mx</Link> y/o que le sea enviado a la dirección de correo electrónico que ha registrado.</p>
                                <p>Sears le notificará cuando su Estado de Cuenta se encuentre disponible para consulta y descarga, con al menos 10 días naturales previo a la fecha límite de pago del ciclo de facturación correspondiente, mediante el envío de un mensaje a la dirección de correo electrónico registrado y/o a través de un mensaje de texto (SMS/USSD).</p>
                                <p>El envío y puesta a disposición del estado de cuenta a través de medios electrónicos no tiene costo.</p>
                                <p>Usted podrá cambiar o actualizar la dirección de correo electrónico registrada a través de Servicio al Cliente en Tiendas Sears ó bien llamando a nuestro Centro de Atención a Clientes Sears al <a href="tel:018009002123">01-800-900-2123</a>.</p>
                                <p>Si la dirección de correo electrónico proporcionada es incorrecta o no válida o no ha sido confirmada, o los correos electrónicos que se le envíen sean devueltos o no son recibidos; Sears suspenderá el envío del Estado de Cuenta a la dirección de correo electrónico registrada hasta que la dirección de correo electrónico sea confirmada o una nueva dirección sea registrada; no obstante, su estado de cuenta estará disponible para consulta y descarga a través del portal de autogestión <Link to="/">www.sears.com.mx</Link> dentro del plazo previamente señalado.</p>
                                <p>Es responsabilidad de usted confirmar su dirección de correo electrónico y asegurarse que los correos que le envíe Sears no sean catalogados por su aplicación de correo electrónico como correo no deseado, SPAM, Junk o Bulk.</p>
                                <p>Sears no será responsable en caso de que el correo electrónico mensual informando que su estado de cuenta está disponible no sea recibido por caso fortuito, fuerza mayor, fallas del sistema de cualquiera de las partes, interrupción en los sistemas de comunicación en línea, o cualquier otra causa fuera del control de Sears. En estos casos usted podrá solicitar su saldo marcando al al <a href="tel:018009002123">01-800-900-2123</a>, en cualquier área de Servicio en Cliente en Tienda Sears o accediendo directamente al portal de autogestión <Link to="/">www.sears.com.mx</Link>. El hecho de no recibir el correo electrónico o no acceder al portal de autogestión <Link to="/">www.sears.com.mx</Link> no le exime de su obligación de pago. Evite que su cuenta presente atraso y pague a tiempo.</p>
                                <p>En caso de requerirlo, usted podrá acudir a cualquier área de Servicio en Cliente en Tienda Sears y solicitar sin costo la impresión de su Estado de Cuenta.</p>
                            </div>
                        </div>

                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function TerminosCondicionesMobile() {

    const history = useHistory();
    const isNativeApp = window.location.search.indexOf('isNativeApp=true')
    // cerrar modal
    const onClose = () => {
        // history.goBack(-1);
        window.location.href = "/acerca-sears"
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Terminos y Condiciones');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">
                    {isNativeApp < 0
                        ?
                            
                            <div className="contentCloseAN">
                                <div className="btn closeBack" onClick={onClose} ></div>
                                <p className="title">Términos y Condiciones Paperless</p>
                            </div>
                        : null 
                    }
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <div className="contenedorImagenes">
                                    <ul>
                                        <img src="/img/logo-sears.svg" alt={"logo-sears"} width={100} />
                                    </ul>
                                </div>

                                <p className="pSliderTitleLred">Estado de Cuenta Sears</p>

                                <p className="pSliderL">Al aceptar éstos términos y condiciones usted está de acuerdo en que:</p>

                                <p className="pSliderL">El Estado de Cuenta que Sears Operadora México S.A. de C.V emita mensualmente le sea puesto a su disposición en formato electrónico a través del portal de autogestión en Internet: <Link to="/">www.sears.com.mx</Link> y/o que le sea enviado a la dirección de correo electrónico que ha registrado.</p>

                                <p className="pSliderL">Sears le notificará cuando su Estado de Cuenta se encuentre disponible para consulta y descarga, con al menos 10 días naturales previo a la fecha límite de pago del ciclo de facturación correspondiente, mediante el envío de un mensaje a la dirección de correo electrónico registrado y/o a través de un mensaje de texto (SMS/USSD).</p>

                                <p className="pSliderL">El envío y puesta a disposición del estado de cuenta a través de medios electrónicos no tiene costo.</p>

                                <p className="pSliderL">Usted podrá cambiar o actualizar la dirección de correo electrónico registrada a través de Servicio al Cliente en Tiendas Sears ó bien llamando a nuestro Centro de Atención a Clientes Sears al <a href="tel:018009002123">01-800-900-2123</a>.</p>

                                <p className="pSliderL">Si la dirección de correo electrónico proporcionada es incorrecta o no válida o no ha sido confirmada, o los correos electrónicos que se le envíen sean devueltos o no son recibidos; Sears suspenderá el envío del Estado de Cuenta a la dirección de correo electrónico registrada hasta que la dirección de correo electrónico sea confirmada o una nueva dirección sea registrada; no obstante, su estado de cuenta estará disponible para consulta y descarga a través del portal de autogestión <Link to="/">www.sears.com.mx</Link> dentro del plazo previamente señalado.</p>

                                <p className="pSliderL">Es responsabilidad de usted confirmar su dirección de correo electrónico y asegurarse que los correos que le envíe Sears no sean catalogados por su aplicación de correo electrónico como correo no deseado, SPAM, Junk o Bulk.</p>

                                <p className="pSliderL">Sears no será responsable en caso de que el correo electrónico mensual informando que su estado de cuenta está disponible no sea recibido por caso fortuito, fuerza mayor, fallas del sistema de cualquiera de las partes, interrupción en los sistemas de comunicación en línea, o cualquier otra causa fuera del control de Sears. En estos casos usted podrá solicitar su saldo marcando al al <a href="tel:018009002123">01-800-900-2123</a>, en cualquier área de Servicio en Cliente en Tienda Sears o accediendo directamente al portal de autogestión <Link to="/">www.sears.com.mx</Link>. El hecho de no recibir el correo electrónico o no acceder al portal de autogestión <Link to="/">www.sears.com.mx</Link> no le exime de su obligación de pago. Evite que su cuenta presente atraso y pague a tiempo.</p>

                                <p className="pSliderL">En caso de requerirlo, usted podrá acudir a cualquier área de Servicio en Cliente en Tienda Sears y solicitar sin costo la impresión de su Estado de Cuenta.</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function TerminosCondiciones() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Terminos y Condiciones');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <TerminosCondicionesDeskop />

                : isMobile === 'movile'
                    ? null
                    : <TerminosCondicionesMobile />
            }
        </>
    )
}