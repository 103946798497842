import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuAutocenter } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory, Link } from 'react-router-dom';

export function FormasPagoAyudaDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica forma de pago Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuAutocenter />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Formas de Pago</p>
                            </div>
                        </section>

                        <div className="contentFormasP">
                            <div className="bxFp">
                                <p className="sub">En tus compras por Internet aceptamos las siguientes formas de pago. Puedes consultar en cada caso las observaciones para cada forma de pago.</p>

                                <ul className="ulBxFp">
                                    <li>Tarjeta de Crédito Sears y Puntos Sears.</li>
                                    <li>Monedero Sears.</li>
                                    <li>Paypal</li>
                                    <li>Tarjetas de Crédito Bancarias Emitidas en México.</li>
                                    {/* <li>Depósito Bancario/Transferencia Electrónica en México.</li> */}
                                    {/* <li>Formas de Pago desde el Extranjero / Foreign Payment Forms</li> */}
                                </ul>
                            </div>

                            <div className="bxFp1">
                                <h3 className="tituloFpRed">Tarjeta de Crédito Sears y Puntos Sears</h3>
                                <div className="contenedorTarjetaSears">
                                    <img src="/img/tarjetaFp.png" alt="tarjeta-sears" />
                                    <p className="p1fp center">El sistema te pedirá ingresar tu número de Tarjeta Sears, así como los datos a donde llega tu estado de cuenta mensualmente.</p>
                                </div>



                                <h5>Observaciones:</h5>
                                <ul className="ulBxFp1">
                                    <li>La compra entra directo a tu Cuenta Revolvente. Solo cuando existan promociones con planes de pago a mensualidades sin intereses podrás elegir tu plan de pago. ¡Mantente al pendiente de nuestras promociones!</li>
                                    <li>A partir del 29 de mayo de 2014 puedes solicitar tu NIP en la tienda más cercana a tu domicilio lo que te brindará Seguridad en tus compras a través de Sears Internet además el envío de tus mercancias será más rápido.</li>
                                    <li>Si no cuentas con tu NIP y por seguridad de la cuenta, el titular de la tarjeta de crédito será contactado vía telefónica para validar que aprueba la compra y así evitar que un tercero no lo haya realizado sin su consentimiento.</li>
                                    <li>En el caso de puntos, puedes consultar la siguiente dirección para conocer más sobre su utilización, valor y obtención.</li>
                                    <li>Si paga con tarjeta Sears sólo se podrán emitir facturas al nombre del titular de la cuenta. Por política de crédito Sears no se emiten facturas a nombre de empresas. Crédito Sears no emite tarjetas empresariales.</li>
                                </ul>
                            </div>

                            <div className="bxFp1">
                                <h3 className="tituloFpRed">Monedero Sears</h3>
                                <div className="contenedorTarjetaSears">
                                    <img src="/img/monedero-sears-azul.jpg" alt="monedero-sears" />
                                </div>

                                <div className="textMonedero">
                                    <p className="p1fp">Podrás utilizar el monedero obtenido en tiendas en los casos en que, debido a alguna venta especial o promoción, se te abone un porcentaje de tu compra en este medio.</p>
                                    <p className="p2fp">Deberás proporcionar el número de monedero que aparece en la parte inferior de tu monedero.</p>
                                </div>
                            </div>

                            <div className="bxFp1">
                                <h3 className="tituloFpRed">Pague con PayPal: Más rápido. Más seguro. Más fácil</h3>
                                <p className="p1fp">PayPal es un servicio que le permite pagar, enviar dinero y aceptar pagos sin revelar sus detalles financieros, ya que la información de su tarjeta de crédito se almacena de manera segura en la cuenta PayPal.</p>

                                <div className="contPaypal">
                                    <div className="paypal1">
                                        <img src="/img/candado.png" alt="paypal1"></img>
                                        <h5 className="center">Realice transacciones con la máxima seguridad y protección</h5>
                                        <p className="p2fp center">PayPal utiliza la más reciente tecnología de cifrado de datos y sistema a prueba de fraudes para mantener su información segura, reduciendo así el riesgo de fraudes y robo de identidad en línea.</p>

                                    </div>

                                    <div className="paypal1">
                                        <img src="/img/credito.png" alt="paypal2"></img>
                                        <h5 className="center">Usted elige cómo pagar</h5>
                                        <p className="p2fp center">Registre su tarjeta de débito o crédito en su cuenta PayPal. Podrá pagar de manera fácil si selecciona PayPal en el pago, inicia sesión en su cuenta PayPal y confirma su pago. Nosotros concluimos el proceso por usted, sin compartir sus detalles financieros con los comercios ni vendedores.</p>
                                    </div>

                                    <div className="paypal1">
                                        <img src="/img/puntero.png" alt="paypal3"></img>
                                        <h5 className="center">Es fácil y no tiene costo</h5>
                                        <p className="p2fp center">Simplemente seleccione PayPal cuando elija la opción de pago en este sitio, lo que le permitirá abrir rápidamente una cuenta PayPal y asociar su forma de pago para concluir la compra. Podrá utilizar su cuenta PayPal para comprar de manera segura a millones de comercios y vendedores de todo el mundo, donde quiera que vea el logotipo de PayPal.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="bxFp1">
                                <h3 className="tituloFpRed">Tarjetas de Crédito Bancarias Emitidas en México</h3>

                                <ul className="ulBxFp1Ima">
                                    <li><img src="/img/visaFP.png" alt="visa"></img></li>
                                    <li><img src="/img/mastercardFP.png" alt="master"></img></li>
                                    <li><img src="/img/americanFP.png" alt="american"></img></li>
                                </ul>

                                <p className="p1fp paddingt">Al momento de hacer tu compra sistema te pedirá que ingreses la siguiente información:</p>

                                <ul className="ulBxFp1">
                                    <li>Nombre como aparece en la Tarjeta de Crédito.</li>
                                    <li>Número de Tarjeta de Crédito.</li>
                                    <li>Tipo de Tarjeta de Crédito. (Sears, Visa, Master Card o American Express)</li>
                                    <li>Fecha de Expiración. (No aplica para las tarjetas Sears). Los 3 dígitos (Visa y MasterCard) o 4 dígitos (American Express) de seguridad.</li>
                                    <li>Informacion AVS (Nombre y Direccion a donde llega tu Estado de Cuenta).</li>
                                </ul>

                                <h5>Observaciones</h5>

                                <ul className="ulBxFp1">
                                    <li>La compra no entra a ningún plan de meses sin intereses debido a los precios especiales exclusivos que manejamos en Internet.</li>
                                    <li>Por tu seguridad, las transacciones realizadas con tu tarjeta se validan tanto en línea al momento de realizar tu compra, así como consultando a tu banco despues de realizada esta. De igual manera, el titular de la tarjeta de crédito puede ser localizado vía telefónica para validar que aprueba la compra y un tercero no lo hizo en su nombre sin su consentimiento.</li>
                                </ul>

                            </div>

                            <div className="bxFp1">
                                <h3 className="tituloFpRed">SafetyPay</h3>

                                <div className="bxSafety">
                                    <div className="princiapSafety">
                                        <div className="imgSafety">
                                            <img src="/img/cardSafety.png" alt="safetyPay"></img>
                                        </div>
                                        <h5 className="center">¿Qué es SafetyPay?</h5>
                                        <p className="p1fp center">SafetyPay es el nuevo medio de Pago Seguro para realizar compras por Internet, pagando a través de tu Banca en Línea.</p>
                                    </div>

                                    <div className="princiapSafety">
                                        <div className="imgSafety">
                                            <img src="/img/cardSafety2.png" alt="beneficios-safetyPay"></img>
                                        </div>
                                        <h5 className="center">Beneficios</h5>
                                        <ul className="ulBxFp1">
                                            <li>Sin revelar la información de tus cuentas.</li>
                                            <li>Sin registrarte o suscribirte.</li>
                                            <li>Sin cobro de comisiones al realizar tus compras.</li>
                                            <li>Sin riesgo de fraude, ya que la transacción queda entre tu Banco y tú.</li>
                                        </ul>
                                    </div>

                                    <div className="princiapSafety">
                                        <div className="imgSafety">
                                            <img src="/img/interrogation.png" alt="como-funciona-safetyPay"></img>
                                        </div>
                                        <h5 className="center">¿Cómo funciona?</h5>

                                        <ul className="ulBxFp2">
                                            <li>Elige el producto que deseas comprar en www.sears.com.mx.</li>
                                            <li>Selecciona SafetyPay como forma de pago y conserva el Número de Transacción y Monto a Pagar.</li>
                                            <li>Elige tu banco* y serás direccionado a tu Banca en Línea, para confirmar ahí tu pago.</li>
                                        </ul>
                                    </div>
                                </div>

                                <p className="p1fp">¡Listo! ya pagaste desde tu cuenta de débito (Nómina, Cheques o Ahorro), con toda la seguridad que te ofrece tu Banco.</p>
                                <p className="p1fp">Tanto Sears como tú, reciben la notificación del pago de tu compra.</p>


                                <div className='bxFp1'>
                                    <h3 className="tituloFpRed">Tiendas de Conveniencia</h3>

                                    <div className="contenedorOxxo">
                                        <img src="/img/oxxopay_conekta.svg" alt="oxxo" className="city"></img>
                                    </div>

                                    <div className="textOxxo">
                                        <p className="p1fp">Si lo que prefieres es pagar con efectivo en el horario y día que tu prefieras, sears.com pone a tu servicio nuestra opción de pagos en las tiendas de conveniencia OXXO, así que ya no hay excusas para comprar lo que deseas, fácil, rápido, sencillo y seguro siempre en sears.com</p>
                                    </div>

                                </div>


                                {/* <div className="bxFp1">
                                    <h3 className="tituloFpRed">Depósito Bancario en México</h3>

                                    <div className="bxDeposito">

                                        <div className="generalDeposito">
                                            <div className="imgDepo">
                                                <img src="/img/citibanamex30.png" alt="banamex" className="city"></img>
                                            </div>

                                            <h5 className="center">Banamex</h5>
                                            <ul className="ulBxFp1">
                                                <li>Nombre del Beneficiario: Sears Operadora Mexico, S.A. de C.V.</li>
                                                <li>Número de Sucursal: 870.</li>
                                                <li>Número de Cuenta: 568173.</li>
                                                <li>Número de Cuenta CLABE (transferencias electrónicas): 002 180 08700568173 4</li>
                                                <li>Número de Referencia: Su número de pedido.</li>
                                                <li>Importe del depósito: importe total de su pedido.</li>
                                            </ul>
                                        </div>

                                        <div className="generalDeposito">
                                            <div className="imgDepo">
                                                <img src="/img/bbva30.png" alt="bbva" className="bbva"></img>
                                            </div>
                                            <h5 className="center">BBVA Bancomer</h5>
                                            <ul className="ulBxFp1">
                                                <li>Nombre del Cliente: Sears Operadora Mexico, S.A. de C.V.</li>
                                                <li>No de Cuenta/Contrato/convenio CIE: 0100710733.</li>
                                                <li>Señalar que es un Depósito/Pago</li>
                                                <li>Referencia (CIE / Depósito Referenciado): Número de su pedido.</li>
                                                <li>Número de su pedido.</li>
                                                <li>Número de Cuenta CLABE (Transferencias electrónicas): 012 180 00100710733 4</li>
                                            </ul>
                                        </div>

                                        <div className="generalDeposito">
                                            <div className="imgDepo">
                                                <img src="/img/santander30.png" alt="santander" className="santander"></img>
                                            </div>
                                            <h5 className="center">Santander</h5>

                                            <ul className="ulBxFp1">
                                                <li>Número de Cuenta: 65-50125112-4.</li>
                                                <li>Nombre del Cliente: Sears Operadora Mexico, S.A. de C.V.</li>
                                                <li>Nombre del Depositante o Pagador: anotar el nombre de quien realizó el pedido en la página de Sears.</li>
                                                <li>Referencia: número de su pedido.</li>
                                                <li>Total del Depósito: Importe total de su pedido.</li>
                                            </ul>
                                        </div>

                                        <div>
                                            <div className="imgDepo">
                                                <img src="/img/logo-inbursa.svg" alt="inbursa" className="bbva"></img>
                                            </div>
                                            <h5 className="center">Inbursa</h5>
                                            <ul className="ulBxFp1">
                                                <li className="pSliderL">Número de Cuenta: 11991820011</li>
                                                <li className="pSliderL">CLABE: 036180119918200117</li>
                                                <li className="pSliderL">Nombre del Cliente: Sears Operadora Mexico, S.A. de C.V.</li>
                                                <li className="pSliderL">Nombre del Depositante o Pagador: anotar el nombre de quien realizó el pedido en la página de Sears.</li>
                                                <li className="pSliderL">Referencia: número de su Pedido.</li>
                                                <li className="pSliderL">Total del Depósito: Importe Total de su pedido.</li>
                                                <li className="pSliderL"> "Si tu pago es con cheque, debe expedirlo a nombre de Sears Operadora Mexico, S.A. de C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <h5 className="h5depo">Para uso exclusivo de Compras Por Internet. Ninguna cantidad depositada a esta cuenta será abonada a pago de Crédito Sears.</h5>
                                    <p className="p1fp">Si tu pago es con cheque, debe expedirlo a nombre de Sears Operadora Mexico, S.A. de C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago.</p>
                                </div>*/}

                                {/* <div className="bxFp1">
                                    <h3 className="tituloFpRed">Formas de Pago desde El Extranjero / Foreign Payment Forms</h3>

                                    <div className="extranjero">
                                        <img src="/img/extranjero.png" alt="deposito-extranjero"></img>
                                    </div>

                                    <div className="conExtra">
                                        <ul className="ulBxFp1None">
                                            <li className="h5depo paddingt10">Depósito Bancario EE.UU/ USA Wire Deposit.</li>
                                            <li>Banco/Bank: JP Morgan Chase Manhatan Bank.</li>
                                            <li>Plaza: New York, NY USA.</li>
                                        </ul>

                                        <ul className="ulBxFp1None">
                                            <li>Aba: 021 000 021</li>
                                            <li>Referencia/Reference: Banco Inbursa SA (México)</li>
                                            <li>No. de cuenta/Account Number: 400 475 464</li>
                                        </ul>

                                        <ul className="ulBxFp1None">
                                            <li>Moneda Extranjera/Currency: USD</li>
                                            <li>Para Acreditar a la cuenta/ Beneficiary Account: 012 09001 006 7</li>
                                            <li>Beneficiario/Beneficiary Name: Sears Operadora Mexico, SA de CV</li>
                                        </ul>

                                    </div>

                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function FormasPagoAyudaMobile() {

    const history = useHistory();

    // // cerrar modal
    // const onClose = () => {
    //     history.goBack(-1);
    // }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Compra Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const backHistory = () => {
        history.go(-1);
    };

    return (
        <>
            <div className="sectionTitleServicioClientePagos">
                <button onClick={backHistory}>Formas de Pago</button>
            </div>

            <div className="opcionesCliente">
                <div className="bxCopyFp">
                    <p>Aceptamos las siguientes formas de pago. Para conocer más de ellas da clic en la que te interese.</p>
                </div>

                <ul className="listaExterna liPagos">
                    <Link to="/formas-pago/credito-sears" className="enlaces">
                        <li className="tarjetaSearsICO"><span className="nombre np">Tarjeta de Crédito Sears y Puntos Sears</span> <span className="icono"></span></li>
                    </Link>

                    <Link to="/formas-pago/monedero" className="enlaces">
                        <li className="monederoICO"><span className="nombre np">Monedero Sears</span> <span className="icono"></span></li>
                    </Link>

                    <Link to="/formas-pago/paypal" className="enlaces">
                        <li className="paypalICO"><span className="nombre np">Paypal</span> <span className="icono"></span></li>
                    </Link>
                    <Link to="/formas-pago/tarjetas-bancarias" className="enlaces">
                        <li className="tarjetasICO"><span className="nombre np">Tarjetas de débito y crédito bancarias</span> <span className="icono"></span></li>
                    </Link>

                    <Link to="/formas-pago/conveniencia" className="enlaces">
                        <li className="convenienciaICO"><span className="nombre np">Tienda de Conveniencia</span> <span className="icono"></span></li>
                    </Link>

                    {/* <Link to="/formas-pago/deposito" className="enlaces">
                        <li className="depositoICO"><span className="nombre np">Depósito bancario / Transferencia electrónica</span> <span className="icono"></span></li>
                    </Link> */}

                    {/* <Link to="/formas-pago/extranjero" className="enlaces">
                        <li className="extranjeroICO"><span className="nombre np">Formas de pago desde el extranjero</span> <span className="icono"></span></li>
                    </Link> */}

                </ul>
            </div>

            {history.location.pathname === "/formas-pago/credito-sears" && <FormasPagoCreditoSears />}

            {history.location.pathname === "/formas-pago/monedero" && <FormasPagoMonedero />}

            {history.location.pathname === "/formas-pago/paypal" && <FormasPagoPaypal />}

            {history.location.pathname === "/formas-pago/tarjetas-bancarias" && <FormasPagoTarjetasBancarias />}

            {history.location.pathname === "/formas-pago/deposito" && <FormasPagoDeposito />}

            {history.location.pathname === "/formas-pago/extranjero" && <FormasPagoExtranjero />}

        </>
    )
}

export function FormasPagoCreditoSears() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'forma pago - credito sears Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN2">
                        <div className="btn closeAN2" onClick={onClose} ></div>
                        <p className="title">Tarjeta de crédito y Puntos Sears</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <p className="pSliderL">Tendrás que ingresar tu número de Tarjeta Sears, así comos los datos a donde llega tu estado de cuenta mensualmente.</p>

                                <p className="pSliderTitleL">Observaciones:</p>
                                <p className="pSliderL">La compra entra directo a tu Cuenta Revolvente. Solo cuando existan promociones con planes de pago a mensualidades sin intereses podrás elegir tu plan de pago. ¡Mantente al pendiente de nuestras promociones!</p>
                                <p className="pSliderL">Puedes solicitar tu NIP en la tienda más cercana a tu domicilio lo que te brindará Seguridad en tus compras a través de Sears Internet, además el envío de tus mercancias será más rápido.</p>
                                <p className="pSliderL">Si no cuentas con tu NIP y por seguridad de la cuenta, el titular de la tarjeta de crédito será contactado vía telefónica para validar que aprueba la compra y así evitar que un tercero no lo haya realizado sin su consentimiento.</p>
                                <p className="pSliderL">En el caso de puntos, puedes consultar la siguiente dirección para conocer más sobre su utilización, valor y obtención.</p>
                                <p className="pSliderL">Si paga con tarjeta Sears sólo se podrán emitir facturas al nombre del titular de la cuenta. Por política de crédito Sears no se emiten facturas a nombre de empresas. Crédito Sears no emite tarjetas empresariales.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export function FormasPagoMonedero() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'forma pago - monedero Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN2">
                        <div className="btn closeAN2" onClick={onClose} ></div>
                        <p className="title">Monedero Sears</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <p className="pSliderL">Podrás utilizar el monedero obtenido en tiendas en los casos en los que, debido a alguna venta especial o promoción, se te abone un porcentaje de tu compra en este medio.</p>

                                <p className="pSliderL">Deberás ingresar el número de monedero que aparece en la parte inferior de tu monedero.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export function FormasPagoPaypal() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'forma pago - paypal Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN2">
                        <div className="btn closeAN2" onClick={onClose} ></div>
                        <p className="title">Paypal</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <p className="pSliderL">Pague con PayPal: Más rápido. Más seguro. Más fácil. </p>
                                <p className="pSliderL">PayPal es un servicio que le permite pagar, enviar dinero y aceptar pagos sin revelar sus detalles financieros, ya que la información de su tarjeta de crédito se almacena de manera segura en la cuenta PayPal.</p>

                                <p className="pSliderL">Realice transacciones con la máxima seguridad y protección</p>
                                <div className="bxPaypal">
                                    <img src="/img/candado.png" alt="seguridad" />
                                    <p className="pSliderL">PayPal utiliza la más reciente tecnología de cifrado de datos y sistema a prueba de fraudes para mantener su información segura, reduciendo así el riesgo de fraudes y robo de identidad en línea.</p>
                                </div>

                                <p className="pSliderL">Usted elige cómo pagar</p>
                                <div className="bxPaypal">
                                    <img src="/img/credito.png" alt="formas" />
                                    <p className="pSliderL">Registre su tarjeta de débito o crédito en su cuenta PayPal. Podrá pagar de manera fácil si selecciona PayPal en el pago, inicia sesión en su cuenta PayPal y confirma su pago. Nosotros concluimos el proceso por usted, sin compartir sus detalles financieros con los comercios ni vendedores.</p>
                                </div>

                                <p className="pSliderL">Es fácil y no tiene costo</p>
                                <div className="bxPaypal">
                                    <img src="/img/puntero.png" alt="facilidad" />
                                    <p className="pSliderL">Simplemente seleccione PayPal cuando elija la opción de pago en este sitio, lo que le permitirá abrir rápidamente una cuenta PayPal y asociar su forma de pago para concluir la compra. Podrá utilizar su cuenta PayPal para comprar de manera segura a millones de comercios y vendedores de todo el mundo, donde quiera que vea el logotipo de PayPal. Cada día, 100 millones de personas utilizan PayPal en 193 mercados y con 26 divisas distintas.</p>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export function FormasPagoTarjetasBancarias() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'forma pago - tarjetas bancarias Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN2">
                        <div className="btn closeAN2" onClick={onClose} ></div>
                        <p className="title">Tarjeta de débito y crédito</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">

                                <p className="pSliderL">Aceptamos las siguientes tarjetas de débito y crédito:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Visa</li>
                                    <li>Master Card</li>
                                    <li>American Express</li>
                                </ul>

                                <p className="pSliderL">Al momento de hacer tu compra deberás ingresar la siguiente información:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Nombre como aparece en la Tarjeta de Crédito.</li>
                                    <li>Número de Tarjeta de Crédito.</li>
                                    <li>Tipo de Tarjeta de Crédito.(Sears, Visa, Master Card o American Express)</li>
                                    <li>Fecha de Expiración. (No aplica para las tarjetas Sears). </li>
                                    <li>Los 3 dígitos (Visa y MasterCard) o 4 dígitos (American Express) de seguridad.</li>
                                    <li>Información AVS (Nombre y Dirección a donde llega tu Estado de Cuenta).</li>
                                </ul>

                                <p className="pSliderTitleL">Observaciones:</p>

                                <p className="pSliderL">La compra no entra a ningún plan de meses sin intereses debido a los precios especiales exclusivos que manejamos en Internet.</p>

                                <p className="pSliderL">Por tu seguridad,las transacciones realizadas con tu tarjeta se validan tanto en línea al momento de realizar tu compra, así como consultando a tu banco despues de realizada esta. De igual manera, el titular de la tarjeta de crédito puede ser localizado vía telefonica para validar que aprueba la compra y un tercero no lo hizo en su nombre sin su consentimiento.</p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export function FormasPagoDeposito() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'forma pago - deposito Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN2">
                        <div className="btn closeAN2" onClick={onClose} ></div>
                        <p className="title">Depósito y Transferencia bancaria</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <p className="pSliderTitleL colorRed">Banamex</p>
                                <p className="pSliderL">Nombre del Beneficiario: Sears Operadora Mexico, S.A. de C.V.</p>
                                <p className="pSliderL">Número de Sucursal: 870.</p>
                                <p className="pSliderL">Número de Cuenta: 568173.</p>
                                <p className="pSliderL">Número de Cuenta CLABE (transferencias electrónicas): 002 180 08700568173 4</p>
                                <p className="pSliderL">Número de Referencia: Su número de pedido.</p>
                                <p className="pSliderL">Importe del depósito: importe total de su pedido.</p>

                                <p className="pSliderTitleL colorRed">BBVA Bancomer</p>
                                <p className="pSliderL">Nombre del Cliente: Sears Operadora Mexico, S.A. de C.V.</p>
                                <p className="pSliderL">No de Cuenta/Contrato/convenio CIE: 0100710733.</p>
                                <p className="pSliderL">Señalar que es un Depósito/Pago</p>
                                <p className="pSliderL">Si su pago es con cheque, debe expedirlo a nombre de Sears Operadora Mexico, S.A. de C.V.</p>
                                <p className="pSliderL">Referencia (CIE / Depósito Referenciado): Número de su Pedido.</p>
                                <p className="pSliderL">Número de Cuenta CLABE (Transferencias electrónicas): 012 180 00100710733 4</p>

                                <p className="pSliderTitleL colorRed">Santander</p>
                                <p className="pSliderL">Número de Cuenta: 65-50125112-4</p>
                                <p className="pSliderL">Nombre del Cliente: Sears Operadora Mexico, S.A. de C.V.</p>
                                <p className="pSliderL">Nombre del Depositante o Pagador: anotar el nombre de quien realizó el pedido en la página de Sears.</p>
                                <p className="pSliderL">Referencia: número de su Pedido.</p>
                                <p className="pSliderL">Total del Depósito: Importe Total de su pedido.</p>
                                <p className="pSliderL"> "Si tu pago es con cheque, debe expedirlo a nombre de Sears Operadora Mexico, S.A. de C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago.</p>

                                <p className="pSliderTitleL colorRed">Inbursa</p>
                                <p className="pSliderL">Número de Cuenta: 11991820011</p>
                                <p className="pSliderL">CLABE: 036180119918200117</p>
                                <p className="pSliderL">Nombre del Cliente: Sears Operadora Mexico, S.A. de C.V.</p>
                                <p className="pSliderL">Nombre del Depositante o Pagador: anotar el nombre de quien realizó el pedido en la página de Sears.</p>
                                <p className="pSliderL">Referencia: número de su Pedido.</p>
                                <p className="pSliderL">Total del Depósito: Importe Total de su pedido.</p>
                                <p className="pSliderL"> "Si tu pago es con cheque, debe expedirlo a nombre de Sears Operadora Mexico, S.A. de C.V., el pedido será liberado a las 48 hrs hábiles posteriores al pago.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export function FormasPagoExtranjero() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'forma pago - extranjero Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentCloseAN2">
                        <div className="btn closeAN2" onClick={onClose} ></div>
                        <p className="title">Pagos desde el extranjero</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">
                                <p className="pSliderTitleL colorRed">Depósito Bancario EE.UU/ USA Wire Deposit.</p>

                                <p className="pSliderL">Banco/Bank: JP Morgan Chase Manhatan Bank</p>
                                <p className="pSliderL">Plaza: New York, NY USA</p>
                                <p className="pSliderL">Aba: 021 000 021</p>
                                <p className="pSliderL">Referencia/Reference: Banco Inbursa SA (México)</p>
                                <p className="pSliderL">No. de cuenta/Account Number: 400 475 464</p>
                                <p className="pSliderL">Moneda Extranjera/Currency: USD</p>
                                <p className="pSliderL">Para Acreditar a la cuenta/ Beneficiary Account : 012 09001 006 7</p>
                                <p className="pSliderL">Beneficiario/Beneficiary Name: Sears Operadora Mexico, SA de CV</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export function FormasPagoConveniencia() {
    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        history.goBack(-1);
    };

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'forma pago - tiendas de conveniencia Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">
                    <div className="contentCloseAN2">
                        <div className="btn closeAN2" onClick={onClose} ></div>
                        <p className="title">Tienda de Conveniencia</p>
                    </div>

                    <div className="dataModal">
                        <div className="boxAN margintop20">
                            <p className="pSliderL"></p>Si lo que prefieres es pagar con efectivo en el horario y día que tu prefieras, sears.com pone a tu servicio nuestra opción de pagos en las tiendas de conveniencia OXXO, así que ya no hay excusas para comprar lo que deseas, fácil, rápido, sencillo y seguro siempre en sears.com
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export function FormasPagoAyuda() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politicas de pago');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {isMobile &&
                isMobile === 'desktop'
                ? <FormasPagoAyudaDesktop />

                : isMobile === 'movile'
                    ? null
                    : <FormasPagoAyudaMobile />
            }
        </>
    )
}