import React, { useState, useEffect } from 'react';
import { getPedido } from 'services/AyudaDinamicaServices';
import CardProductDetail, { CardProductDetailMobile } from 'components/CardProduct/CardMyAccount/CardProductDetail';
import { useHistory } from 'react-router-dom';
import './detallePedido.sass';
import Loading from 'components/Loading';
import Header from 'pages/Header/HeaderNavegacion';
import { MenuMiCuenta } from 'components/MenuMiCuenta';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { useIsMobile } from 'hooks/useIsMobile';
import Footer from 'pages/Footer/FooterDesktop';
import CardProductDetailDesktop from 'components/CardProduct/CardMyAccount/CardProductDetail';

import { decodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export function DetallePedidoDesktop(props) {
    const history = useHistory();
    const params = props.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];

    const [products, setProducts] = useState([]);
    const [buy_date, setBuyDate] = useState();
    const [total, setTotal] = useState(0);
    const [costoEnvio, setCostoEnvio] = useState();
    const [costoProducto, setCostoProducto] = useState();
    const [entregarA, setEntregarA] = useState();
    const [direccionEnvio, setDireccionEnvio] = useState();
    const [, setEstatusPedido] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idFp, setIdFp] = useState();
    const [nombreFp, setNombreFp] = useState();
    const [nombreStatus, setNombreStatus] = useState();

    // MOSTRAR LOADING HASTA QUE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [resultadoPedido]);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "detallePedidoDesckop", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        setTimeout(function(){
            checkValidateSession(true)
            setActionOk('ready')
        },700)
    }, []);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(actionOK === 'ready'){
                setServiceRT(false)
                setActionOk(false)
                getPedido(idPedido)
                .then(setResultadoPedido)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    // se obtiene la informacion que se necesita mostrar del pedido
    useEffect(() => {
        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                setBuyDate(resultadoPedido.pedido[0].buy_date);
                setTotal(resultadoPedido.pedido[0].total);
                setProducts(resultadoPedido.pedido[0].products);
                setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                setCostoEnvio(resultadoPedido.pedido[0].shipping);
                setCostoProducto(resultadoPedido.pedido[0].sub_total);
                setEntregarA(resultadoPedido.pedido[0].deliery_to);
                setDireccionEnvio(resultadoPedido.pedido[0].address);
                setIdFp(resultadoPedido.pedido[0].payment_method.id);
                setNombreFp(resultadoPedido.pedido[0].payment_method.name);
                setNombreStatus(resultadoPedido.pedido[0].status.name);
            }
        }
    }, [resultadoPedido]);

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    };

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    
    useEffect(()=>{
        if(resultadoPedido.pedido){
            console.log("que trae el pedido--> ", resultadoPedido.pedido)
        }
    },[resultadoPedido.pedido])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            {loading && <Loading />}
            <main>
                <section className="miCuentaContainer">
                    <div className="container myAccountSection">

                        <MenuMiCuenta />

                        <div className="detallePedido full">
                            <div className="contentModal">

                                <div className="contentClose">
                                    <div className="btn close" onClick={onClose} ></div>
                                    <p className="title">Pedido No. {idPedido}</p>
                                </div>
                                <div className="dataModal">
                                    <CardProductDetailDesktop total={total} buy_date={buy_date} products={products}
                                        costoEnvio={costoEnvio}
                                        costoProducto={costoProducto}
                                        direccionEnvio={direccionEnvio}
                                        entregarA={entregarA}
                                        idFp={idFp}
                                        nombreFp={nombreFp}
                                        nombreStatus={nombreStatus}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export function DetallePedidoMobile(props) {
    const history = useHistory();
    const params = props.location.pathname;
    const separar = params.split('/');
    const idPedido = separar[3];

    const [products, setProducts] = useState([]);
    const [buy_date, setBuyDate] = useState();
    const [total, setTotal] = useState(0);
    const [costoEnvio, setCostoEnvio] = useState();
    const [costoProducto, setCostoProducto] = useState();
    const [entregarA, setEntregarA] = useState();
    const [direccionEnvio, setDireccionEnvio] = useState();
    const [, setEstatusPedido] = useState(0);
    const [resultadoPedido, setResultadoPedido] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idFp, setIdFp] = useState();
    const [nombreFp, setNombreFp] = useState();
    const [nombreStatus, setNombreStatus] = useState();

    // MOSTRAR LOADING HASTA QUE CARGUE LA INFORMACION
    useEffect(() => {
        if (resultadoPedido) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [resultadoPedido]);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "detallePedidoMovil", timeRef: 1000})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        checkValidateSession(true)
        setActionOk('ready')
    }, []);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                // console.log('------------------------------')
                // console.log('actTimeLS : ', new Date() )
                // console.log('atExp   : ', new Date (atExpLS) )
                // console.log('time ok : ', actTimeLS <= atExpLS)
                // console.log('actionOK: ', actionOK)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(actionOK === 'ready'){
                setServiceRT(false)
                setActionOk(false)
                getPedido(idPedido)
                .then(setResultadoPedido)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    // se obtiene la informacion que se necesita mostrar del pedido
    useEffect(() => {
        if (resultadoPedido) {
            if (resultadoPedido.pedido) {
                setBuyDate(resultadoPedido.pedido[0].buy_date);
                setTotal(resultadoPedido.pedido[0].total);
                setProducts(resultadoPedido.pedido[0].products);
                setEstatusPedido(resultadoPedido.pedido[0].axxi.status_axxi_id);
                setCostoEnvio(resultadoPedido.pedido[0].shipping);
                setCostoProducto(resultadoPedido.pedido[0].sub_total);
                setEntregarA(resultadoPedido.pedido[0].deliery_to);
                setDireccionEnvio(resultadoPedido.pedido[0].address);
                setIdFp(resultadoPedido.pedido[0].payment_method.id);
                setNombreFp(resultadoPedido.pedido[0].payment_method.name);
                setNombreStatus(resultadoPedido.pedido[0].status.name);
            }
        }
    }, [resultadoPedido]);

    // regresar a la pagina anterior
    const onClose = () => {
        history.goBack(-1);
    };

    //recibe la direccion del CP    
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setUpdateCPBar(val);
    }
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    // console.log("que trae el pedido--> ", resultadoPedido.pedido)

    return (
        <>
            {loading && <Loading />}

            <div className="modalDetallePedidoMobile full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Pedido No. {idPedido}</p>
                    </div>
                    <div className="dataModalMobile">
                        <CardProductDetailMobile total={total} buy_date={buy_date} products={products}
                            costoEnvio={costoEnvio}
                            costoProducto={costoProducto}
                            direccionEnvio={direccionEnvio}
                            entregarA={entregarA}
                            idFp={idFp}
                            nombreFp={nombreFp}
                            nombreStatus={nombreStatus}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export function DetallePedido(props) {
    const { isMobile } = useIsMobile();

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <DetallePedidoDesktop location={props.location}
                    />
                    : isMobile === 'movile'
                        ? null
                        : <DetallePedidoMobile location={props.location}
                        />
                : null
            }
        </>
    )
}
