import React, { useState, useEffect } from 'react'
import CurrencyFormat from 'react-currency-format'
import { HIDDEN_DISCOUNT, NO_IMAGE } from 'constants/general'
import { Link } from 'react-router-dom'
import { decodeStorage } from 'functions/storageBase64'
import { getDeliveryDate } from 'services/DeliveryDate'
import { MessageUpdate } from 'components/Modal/MessageUpdate'
import { MessageRemove } from 'components/Modal/MessageRemove'
import ModuleQuantity from 'components/ProductDetail/Quantity'
import ModalPortal from 'components/Modal'
import BarraCP from 'components/BarCP/BarHeaderCP'
import ReactDOM from 'react-dom';
import { repareImage, repareTitleSEO, repareTextHtml } from 'functions/repareDataService'
import { VARIANTS_PER_PORTAL } from 'constants/general';


export function CardProductCartDesktop({ ...props }) {
    const {
        id_Product,
        images,
        cart_quantity = 1,
        title,
        nombreSEO,
        parentStock,
        status,
        selected_size_color,
        features,
        price,
        sale_price,
        discount,
        updateCant,
        removeButton,
        reciveDirection
    } = props


    const codigoPostal = decodeStorage('codigoPostal')
    const idCart = decodeStorage('idCarrito')

    // evalua si los datos del card son de un producto talla color
    let skuChildren, stock, imageProduct
    if (selected_size_color?.sku === undefined) {
        skuChildren = 0
        stock = parentStock
    } else {
        skuChildren = selected_size_color?.sku
        stock = selected_size_color?.stock
    }
    if (images != null) {
        if(images.length){
            if (images[0]?.url?.indexOf('?') >= 0) {
                imageProduct = repareImage(images[0]?.url) + '&scale=350'
                //imageProduct = images[0].url
            } else {
                imageProduct = repareImage(images[0]?.url) + '?scale=350'
                //imageProduct = images[0].url
            }
        }else{imageProduct = NO_IMAGE}
    }

    let newSeo
    if (nombreSEO) {
        newSeo = nombreSEO
    } else {
        newSeo = repareTitleSEO(title)
    }


    const [product, setProduct] = useState({ 'cantidad': cart_quantity })
    //const [arrayProduct, setArrayProduct] = useState({})

    const [showModal, SetShowModal] = useState(false)
    const [typeModale, setTypeModale] = useState('delete')


    // funciones para mostrar el modal
    const showModalRemove = () => {
        setTypeModale('delete')
        SetShowModal(true)
    }
    const showModalUpdate = () => {
        setTypeModale('update')
        SetShowModal(true)
    }
    const handleClose = () => SetShowModal(false)


    //actualiza la cantidad
    useEffect(() => {
        if (product?.cantidad >= 1 && product?.cantidad <= stock && product?.cantidad <= 99) {
            if (cart_quantity !== product?.cantidad) {
                updateCant({ id_product: id_Product, children_sku: skuChildren, quantity: product.cantidad })
            }
        }
    }, [product?.cantidad])
    //}, [cart_quantity, product.cantidad])

    const changeQuantity = (val) => {
        setProduct({ 'cantidad': val })
    }


    //- Funcion que va por la fecha estimada cuando existe un CP
    const [deliveryDateResp, setDeliveryDateResp] = useState()
    useEffect(() => {
        if (codigoPostal) {
            // getDeliveryDate({ idProduct: id_Product, sku: skuChildren })
            //     .then(setDeliveryDateResp)
        }
    }, [codigoPostal, id_Product])

    const [dataShipping, getDataShipping] = useState()
    useEffect(() => {
        if (deliveryDateResp) {
            // getDataShipping(deliveryDateResp.data.shipping)
            //console.log(deliveryDateResp.data.shipping)
        }
    }, [deliveryDateResp])


    // detona modal para eliminar direccion
    const deleteProduct = () => showModalRemove()
    const noRemove = () => SetShowModal(false)
    const siRemove = () => {
        SetShowModal(false)
        removeButton({ id_product: id_Product, children_sku: skuChildren, quantity: Number(product.cantidad) })
    }
    const cantidadModal = (val) => {
        SetShowModal(false)
        setProduct({ 'cantidad': Number(val) })
    }

    const updateProduct = () => {
        showModalUpdate()
    }

    return (
        <article className="card cartDetail">
            {/*(discount >= 10 && (stock && Number(stock) >= 1) && status)
                ?
                <div className="boxGeneralDiscCar">
                    <div className="bxDisc">
                        <span className="discoutnCard">-{discount}%</span>
                    </div>

                </div>
                : null
            */}
            {stock && status
                ? null
                : <div className="messageOut">Producto agotado, se activará cuando tenga inventario</div>
            }

            {features && features?.is_digital
                ? <div className="isDigital">Al aprobar el pago enviaremos tu producto por correo electrónico.</div>
                : null
            }

            <div className="contDataCartProducto">
                <div className="contDataInternoP">


                    <div className="imageDelete">
                        <Link to={`/producto/${id_Product}/${newSeo}/`} title={title}>
                            <img src={images != null ? imageProduct : NO_IMAGE} loading="lazy" alt={title} className={stock && status ? '' : 'soildOut'} width="120" height="120" />
                        </Link>
                    </div>

                    <div className="dataProductCard">
                        <div className="moduleCardTitle">
                            <Link to={`/producto/${id_Product}/${newSeo}/`} title={repareTextHtml(title)}>
                                <p className={stock && status ? 'h3' : 'soildOut'}>{repareTextHtml(title)}</p>
                            </Link>
                        </div>

                        <div className="moduleCardPrice">
                            {stock && status
                                ? <>
                                    {Number(price) !== Number(sale_price) && <div className="boxDiscount">
                                        <span className="h3-precio">
                                            <CurrencyFormat
                                                value={price}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                renderText={value => <p>{value}<span className="mxn"></span></p>}
                                            />
                                        </span>

                                    </div>
                                    }

                                    <div className="boxPrecio">
                                        <div className="priceCartModule">
                                            <div className="Precio480">
                                                <CurrencyFormat
                                                    value={sale_price}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    renderText={value => <p className="precio">{value} MXN</p>}
                                                />
                                                {/* <span className="mxn">MXN</span> */}
                                                {discount <= HIDDEN_DISCOUNT
                                                    ? null
                                                    : <span className="discoutnCard">-{discount}%</span>
                                                }
                                            </div>
                                            {/* <span className="unitario">Precio unitario</span> */}
                                        </div>

                                        {stock && status
                                            ?
                                                <>
                                                    {selected_size_color &&
                                                        Object.keys(selected_size_color)?.length
                                                            ?
                                                                <div className="boxTallaColor">
                                                                    <p className="h3-color">Color: {selected_size_color?.color}</p>
                                                                    <p className="h3-talla">Talla: {selected_size_color?.size}</p>
                                                                </div>
                                                            : null
                                                    }
                                                </>
                                            : (selected_size_color?.color != null && selected_size_color?.size != null
                                                ?
                                                    <div className="tallaColor">
                                                        <p><span className="color">Color: {selected_size_color?.color}</span> <span className="talla">Talla: {selected_size_color?.size}</span></p>
                                                    </div>
                                                : null
                                            )
                                        }

                                        {selected_size_color && selected_size_color?.stock
                                            ? selected_size_color?.fulfillment
                                                ?
                                                    VARIANTS_PER_PORTAL?.express === true &&
                                                        <div className="fullFilment">Express</div>
                                                : null
                                            : features && features?.fulfillment
                                                ?
                                                    VARIANTS_PER_PORTAL?.express === true &&
                                                        <div className="fullFilment">Express</div>
                                                : null
                                        }

                                        {features && features?.is_digital
                                            ? <div className="stickerDigital">Producto Digital</div>
                                            : null
                                        }
                                    </div>
                                </>
                                : null
                            }
                        </div>

                    </div>

                </div>

                <div className="contenedorDeleteCantidad">
                    <span className="deleteItem" onClick={deleteProduct}>Eliminar</span>

                    <div className="moduleCardQuantity">
                        {stock && status
                            ? <>
                                { ((selected_size_color?.stock >= 15 && selected_size_color?.stock <= 30) || (stock >= 15 && stock <= 30))
                                    ? <div className="boxUltimasPiezas">
                                        <p>Últimas piezas</p>
                                    </div>
                                    : null
                                }

                                { ((selected_size_color?.stock >= 2 && selected_size_color?.stock <= 14) || (stock >= 2 && stock <= 14))
                                    ? <div className="boxUltimasPiezas">
                                        {selected_size_color?.stock
                                            ? <p>Últimas {selected_size_color?.stock} piezas</p>
                                            : stock
                                                ? <p>Últimas {stock} piezas</p>
                                                : null
                                        }
                                    </div>
                                    : null
                                }

                                {(Number(selected_size_color?.stock) === 1 || Number(stock) === 1)
                                    ? <div className="boxUltimasPiezas">
                                        <p>Queda 1 pieza</p>
                                    </div>
                                    : null
                                }

                                <div className="boxCantidad">
                                    <p className="pCant">Cantidad:</p>
                                    <ModuleQuantity
                                        stock={stock}
                                        cantBase={cart_quantity}
                                        returnNumber={changeQuantity}
                                        updateProduct={updateProduct}
                                        clase={true}
                                    />
                                </div>

                            </>
                            : null
                        }
                    </div>
                </div>

                {/* {stock && status
                    ? dataShipping && dataShipping.date_text
                        ? <div className="deliveryDateCart">
                            <p className="pEstimada">Entrega estimada:</p>
                            <div className="bxEstimada">
                                <p className="fecha">{dataShipping.date_text} </p>
                                <BarraCP changeCP={reciveDirection} clase="fechaEstimada" />
                            </div>
                        </div>
                        : <BarraCP changeCP={reciveDirection} clase="fechaEstimada" />
                    : null
                } */}

            </div>


            {showModal &&
                <ModalPortalDelete type="modalSB" onClose={handleClose} >
                    {typeModale === "delete"
                        ? <MessageRemove remvoeAction={siRemove} awaitAction={noRemove} />
                        : <MessageUpdate updateAction={cantidadModal} awaitAction={noRemove} cantidad={cart_quantity} stock={stock} />
                    }
                </ModalPortalDelete>
            }
        </article>
    )
}

function ModalDelete({ ...props }) {
    const {
        children,
        type,
    } = props
    return (
        <div className={`modal ${type}`}>
            <div className="contentModal" >
                <div className="dataModal">
                    {children}
                </div>
            </div>
        </div>
    )
}

export function ModalPortalDelete({ ...props }) {
    const {
        children,
        onClose,
        type,
    } = props

    return ReactDOM.createPortal(
        <ModalDelete onClose={onClose} type={type} >
            {children}
        </ModalDelete>,
        document.getElementById('modal-root')
    )
}
