import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { useIsMobile } from 'hooks/useIsMobile';
import { useHistory } from 'react-router-dom';

export function PoliticaDevolucionDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Contact Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Política de Devolución</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <p>Nuestra política de devolución es muy sencilla. Podrás devolver cualquier artículo comprado en Sears Internet por las siguientes causas:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Si el artículo presenta defectos de fabricación.</li>
                                    <li>Si existe equivocación en el artículo enviado, conservando la envoltura original de celofán (emplaye) y sin presentar muestras de maltrato.</li>
                                </ul>

                                <p>En la recepción de mercancía errónea o dañada se aplicará el cambio físico de la misma solo si ésta fue reportada durante las primeras 72 horas posteriores a su entrega, a los siguientes teléfonos:</p>

                                <p className="h3red">Atención a Clientes:</p>
                                <p>Teléfono: D.F. y área Metropolitana <a href="tel:015553451606">(01 55) 53 45 16 06</a></p>
                                <p>Interior de la República <a href="tel:018008368246">(01 800) 836 82 46</a></p>
                                <p>Horario: Lunes a Viernes de 9:00 a 20:00 hrs, Sábado y Domingo de 11:00 a 20:00 hrs.</p>
                                <p>La garantía a productos eléctricos y electrónicos será válida únicamente durante los 30 días naturales a la recepción de los mismos.</p>

                                <p className="h3red">No hay Cambios ni Devoluciones en los siguientes artículos o departamentos:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Joyería fina y de fantasía, Lencería y Cosetería, Ropa Interior para caballero, niño, niña y bebes, perfumería, cosméticos, tratamientos dermatológicos, telefonía celular, Relojería, Software, Consolas de Videojuegos, accesorios y juegos.</li>
                                    <li>En Computación, Tablets, iPods, Telefonía celular y fotografía la garantía es directamente con el proveedor.</li>
                                    <li>Por disposición de PROFECO en colchones no se aceptan devoluciones ni cambios.</li>
                                </ul>
                                <p className="h3red">Telefonía Celular</p>
                                <p>La garantía y servicio en aparatos celulares adquiridos en Sears Internet se aplicará de forma directa con el proveedor Telcel, conforme a sus políticas, procedimientos y restricciones.</p>
                                <p>Para esto deberás acudir al Centro de Servicio Telcel más cercano para la evaluación de tu equipo.</p>
                            </div>



                            <div className="question1">
                                <h2>Procedimiento para la devolución en una tienda Sears</h2>

                                <ul className="ulQuestionDisc">
                                    <li>Si realizas la devolución de la mercancía, deberás dirigirte al departamento de Servicio al cliente de la tienda Sears más cercana, presentando la mercancía, la factura origina ó Ticket de compra y la tarjeta de crédito en la cual se generó el cargo.</li>
                                    <li>En caso de que se haya enviado otra mercancía por equivocación o que presente algún defecto de fabricación, se podrá realizar el cambio físico, si en la tienda Sears a la que acudiste se cuenta con la existencia.</li>
                                    <li>Si la tienda Sears no cuenta con la existencia, podrás escoger otro artículo equivalente de tu preferencia, o en caso contrario, se realizará el reembolso a tu tarjeta de crédito.</li>
                                    <li>Si se envió otra mercancía por equivocación o presenta algún defecto de fabricación, se realizará el reembolso total de la mercancía, más los gastos de envío.</li>
                                    <li>Si únicamente deseas cancelar tu compra, se realizará el reembolso por el importe de la mercancía, sin incluir los gatos de envío.</li>
                                </ul>
                            </div>

                            <div className="question1">
                                <h2>Procedimiento para devoluciones por servicio de mensajería</h2>

                                <ul className="ulQuestionDisc">
                                    <li>Si en tu localidad no cuentas con ninguna tienda Sears, podrás enviar la mercancía, utilizando de preferencia, la misma compañía de mensajería que le entregó la mercancía, asegurando perfectamente el paquete el cual deberá contener la factura original, una nota aclaratoria que indique el motivo de la devolución, y si deseas la reposición del artículo o el reembolso de tu dinero.</li>
                                    <li>Si se envía la mercancía por mensajería, deberás asegurarte de anotar correctamente el siguiente destinatario:</li>
                                </ul>

                                <p className="h3black pbottom0">Ventas Internet Sears / Sears Operadora México, S.A. de C.V.</p>
                                <p className="h3black pbottom0">Norte 45 Nº 1014 Colonia Industrial Vallejo</p>
                                <p className="h3black">México D.F., C.P. 02300</p>

                                <ul className="ulQuestionDisc">
                                    <li>Al momento en que nos sea entregada la devolución, nos pondremos en contacto contigo para confirmar la reposición de la mercancía o el reembolso.</li>
                                    <li>Si no has recibido la mercancía, podrás enviarnos un e-mail a: <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a> ó comunicarte al teléfono:</li>
                                </ul>

                                <p>Teléfono: D.F. y área Metropolitana <a href="tel:015553451606">(01 55) 53 45 16 06</a> Interior de la República <a href="tel:018008368246">(01 800) 836 82 46</a></p>

                                <p className="h3black pbottom0">Horario: Lunes a Viernes de 9:00 a 20:00 hrs, Sábado y Domingo de 11:00 a 20:00 hrs.</p>
                                <p>Preguntas, sugerencias y/o comentarios adicionales, puedes escribirnos a: <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function PoliticaDevolucionMobile() {

    const history = useHistory();

    // cerrar modal
    const onClose = () => {
        window.open('', '_top').close()
        history.goBack(-1);
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Devolucion Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    <div className="contentClose">
                        <div className="btn close" onClick={onClose} ></div>
                        <p className="title">Política de devolución</p>
                    </div>
                    <div className="dataModal">

                        <div className="boxAN">

                            <div className="bxSlider">

                                <p className="pSliderL">Nuestra política de devolución es muy sencilla. Podrás devolver cualquier artículo comprado en Sears Internet por las siguientes causas:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Si el artículo presenta defectos de fabricación.</li>
                                    <li>Si existe equivocación en el artículo enviado, conservando la envoltura original de celofán (emplaye) y sin presentar muestras de maltrato.</li>
                                </ul>

                                <p className="pSliderL">En la recepción de mercancía errónea o dañada se aplicará el cambio físico de la misma solo si ésta fue reportada durante las primeras 72 horas posteriores a su entrega, a los siguientes teléfonos:</p>

                                <p className="pSliderTitleLred">Atención a Clientes:</p>
                                <p className="pSliderL">Teléfono: D.F. y área Metropolitana <a href="tel:015553451606">(01 55) 53 45 16 06</a></p>
                                <p className="pSliderL">Interior de la República <a href="tel:018008368246">(01 800) 836 82 46</a></p>
                                <p className="pSliderL">Horario: Lunes a Viernes de 9:00 a 20:00 hrs, Sábado y Domingo de 11:00 a 20:00 hrs.</p>
                                <p className="pSliderL">La garantía a productos eléctricos y electrónicos será válida únicamente durante los 30 días naturales a la recepción de los mismos.</p>

                                <p className="pSliderTitleLred">No hay Cambios ni Devoluciones en los siguientes artículos o departamentos:</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Joyería fina y de fantasía, Lencería y Cosetería, Ropa Interior para caballero, niño, niña y bebes, perfumería, cosméticos, tratamientos dermatológicos, telefonía celular, Relojería, Software, Consolas de Videojuegos, accesorios y juegos.</li>
                                    <li>En Computación, Tablets, iPods, Telefonía celular y fotografía la garantía es directamente con el proveedor.</li>
                                    <li>Por disposición de PROFECO en colchones no se aceptan devoluciones ni cambios.</li>
                                </ul>
                                <p className="pSliderTitleLred">Telefonía Celular</p>
                                <p className="pSliderL">La garantía y servicio en aparatos celulares adquiridos en Sears Internet se aplicará de forma directa con el proveedor Telcel, conforme a sus políticas, procedimientos y restricciones.</p>
                                <p className="pSliderL">Para esto deberás acudir al Centro de Servicio Telcel más cercano para la evaluación de tu equipo.</p>



                                <h2 className="pSliderTitleLred colorRed">Procedimiento para la devolución en una tienda Sears</h2>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Si realizas la devolución de la mercancía, deberás dirigirte al departamento de Servicio al cliente de la tienda Sears más cercana, presentando la mercancía, la factura origina ó Ticket de compra y la tarjeta de crédito en la cual se generó el cargo.</li>
                                    <li>En caso de que se haya enviado otra mercancía por equivocación o que presente algún defecto de fabricación, se podrá realizar el cambio físico, si en la tienda Sears a la que acudiste se cuenta con la existencia.</li>
                                    <li>Si la tienda Sears no cuenta con la existencia, podrás escoger otro artículo equivalente de tu preferencia, o en caso contrario, se realizará el reembolso a tu tarjeta de crédito.</li>
                                    <li>Si se envió otra mercancía por equivocación o presenta algún defecto de fabricación, se realizará el reembolso total de la mercancía, más los gastos de envío.</li>
                                    <li>Si únicamente deseas cancelar tu compra, se realizará el reembolso por el importe de la mercancía, sin incluir los gatos de envío.</li>
                                </ul>



                                <h2 className="pSliderTitleLred colorRed">Procedimiento para devoluciones por servicio de mensajería</h2>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Si en tu localidad no cuentas con ninguna tienda Sears, podrás enviar la mercancía, utilizando de preferencia, la misma compañía de mensajería que le entregó la mercancía, asegurando perfectamente el paquete el cual deberá contener la factura original, una nota aclaratoria que indique el motivo de la devolución, y si deseas la reposición del artículo o el reembolso de tu dinero.</li>
                                    <li>Si se envía la mercancía por mensajería, deberás asegurarte de anotar correctamente el siguiente destinatario:</li>
                                </ul>

                                <p className="colorNegro bold">Ventas Internet Sears / Sears Operadora México, S.A. de C.V.</p>
                                <p className="colorNegro bold">Norte 45 Nº 1014 Colonia Industrial Vallejo</p>
                                <p className="colorNegro bold pbottom">México D.F., C.P. 02300</p>

                                <ul className="ulDeclaracionesDisc">
                                    <li>Al momento en que nos sea entregada la devolución, nos pondremos en contacto contigo para confirmar la reposición de la mercancía o el reembolso.</li>
                                    <li>Si no has recibido la mercancía, podrás enviarnos un e-mail a: <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a> ó comunicarte al teléfono:</li>
                                </ul>

                                <p className="pbottom">Teléfono: D.F. y área Metropolitana <a href="tel:015553451606">(01 55) 53 45 16 06</a> Interior de la República <a href="tel:018008368246">(01 800) 836 82 46</a></p>

                                <p className="bold colorNegro pbottom">Horario: Lunes a Viernes de 9:00 a 20:00 hrs, Sábado y Domingo de 11:00 a 20:00 hrs.</p>
                                <p>Preguntas, sugerencias y/o comentarios adicionales, puedes escribirnos a: <a href="mailto:sears.internet@sears.com.mx">sears.internet@sears.com.mx</a></p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export function PoliticaDevolucion() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Politica de Devolución');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <PoliticaDevolucionDesktop />

                : isMobile === 'movile'
                    ? null
                    : <PoliticaDevolucionMobile />
            }
        </>
    )
}