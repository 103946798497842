import React, { useState } from 'react';
import './style.sass';
import { BASE_PATH_RESOURCES } from 'constants/general'
import { Link } from 'react-router-dom';


export default function BoxContacto() {

    const [menuView, setMenuView] = useState(false);
    const toggleClass = () => {
        setMenuView(!menuView);
    };

    return (
        <div className="boxRedes">
            <p onClick={toggleClass} className={menuView ? 'active' : null} >
                <Link to="/dudas-comentarios/">CONTÁCTANOS</Link></p>
            <ul>
                <li>
                    <span className="sigTitle">Síguenos:</span>
                </li>
            </ul>

            <div className="redesLogos">
                <ul>
                    <li><a href="https://www.facebook.com/searsmexico" target="_blank" rel="noopener noreferrer" aria-label="icon-face"><img src={`/img/icon-face.svg`} loading="lazy" alt="icon-face" width="15" height="15" /></a></li>
                    <li><a href="https://twitter.com/searsmexico" target="_blank" rel="noopener noreferrer" aria-label="icon-twitter"><img src={`/img/icon-twitter.svg`} loading="lazy" alt="icon-twitter" width="15" height="15" /></a></li>
                    <li><a href="https://www.youtube.com/user/SearsMexico" target="_blank" rel="noopener noreferrer"><img src={`/img/icon-youtube.svg`} loading="lazy" alt="icon-youtube" width="15" height="15" /></a></li>
                    <li><a href="https://www.instagram.com/searsmexico/" target="_blank" rel="noopener noreferrer"><img src={`/img/icon-instagram.svg`} loading="lazy" alt="icon-instagram" width="15" height="15" /></a></li>
                    <li><a href="https://www.tiktok.com/@searsmexico" target="_blank" rel="noopener noreferrer"><img src={`/img/icon-tiktok.svg`} loading="lazy" alt="icon-tiktok" width="15" height="15" /></a></li>
                    <li><a href="https://www.pinterest.com.mx/searsmexico/" target="_blank" rel="noopener noreferrer"><img src={`/img/icon-pinterest.svg`} loading="lazy" alt="icon-pinterest" width="15" height="15" /></a></li>
                </ul>
            </div>
            <ul className="ulEmail">
                <li>
                    <a href="mailto:sears.internet@sears.com.mx" target="_blank" rel="noopener noreferrer">Por email </a>
                </li>
                <li>
                    <a href="https://distintivodigital.profeco.gob.mx/consulta.php?serie=DA21SP63071512" target="_blank" rel="follow noopener noreferrer"><img src="/img/distintivo.svg" alt="logo-profeco" width="130" height="50" loading="lazy"></img></a>
                </li>
            </ul>
        </div>
    )
}