import React, { useState, useEffect, useRef } from 'react';
import './style.sass';
import Footer from 'pages/Footer/FooterDesktop';
import { MisPedidosTemplateDesktop } from 'pages/MiCuenta/MisPedidos/MisPedidosDesktop';
import { MisPedidosTemplateMobile } from 'pages/MiCuenta/MisPedidos/MisPedidosMobile';
import Loading from 'components/Loading';
import getMisPedidos from 'services/getMisPedidos';
import { useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';
import { urlPedidosFunction } from 'functions/urlPedidosFunction';
import { LoginAyudaDinamica } from 'services/AyudaDinamicaServices';
import { useStorage64 } from 'hooks/useStorageBase64';
import { USERNAME, PASSWORD, PUBLICKEY } from 'constants/general';
import { ModalOcurrioUnError } from './AyudaDinamica/vista4-modal';
import ModalPortal from 'components/Modal';

import { decodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';

export default function MisPedidos(props) {
    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'My Account | My Orders Page');
        window.scrollTo(0, 0);
    }, [])

    const { isMobile } = useIsMobile();
    const [loading, setLoading] = useState(true);
    const [pedidos, setPedidos] = useState({ days: null, pages: null });
    const [resultadosPedidos, setResultadosPedidos] = useState([]);
    const [days, setDays] = useState(0);
    const [pages] = useState(1);
    const history = useHistory();
    const [sucessLogin, getSuccessLogin] = useState(false);
    const [accessToken, setAccessToken] = useStorage64('accessToken', '');
    const selectFilter = useRef(null);

    //-------------------------- para la paginacion ---------------------------
    const resultsUrl = urlPedidosFunction();
    const params = props.location.pathname;
    const [paginaActual, setpaginaActual] = useState(1);

    // actualizar parametros
    useEffect(() => { updateParams(); }, [params]);

    // actualizar parametros de la url y recordar campos al actualizar la pagina
    const updateParams = () => {
        setPedidos({
            days: resultsUrl.days,
            pages: resultsUrl.pages,
        });
        setDays(resultsUrl.days);
    };



    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "misPedidos", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    // obtire los pedidos de los filtros seleccionados
    useEffect(() => {
        setLoading(true);
        setTimeout(function(){
            if (pedidos?.pages != null) {
                checkValidateSession(true)
                setActionOk('getPedidos')
            }
        },700)
    }, [pedidos]);

    /*  --------------------------- Inicio --------------------------------------  */
    //  Ajusta Los tiempos y refresca token
    const [serviceRT, setServiceRT] = useState(false)
    const [uD, setUD] = useState(false)
    useEffect(() => {
        if (actionOK) {
            let actTimeLS = Date.now()
            let atExpLS = decodeStorage('access_token_exp') * 1000
            setUD(false)

            // console.log('------------------------------')
            // console.log('actTimeLS : ', new Date() )
            // console.log('atExp   : ', new Date (atExpLS) )
            // console.log('time ok : ', actTimeLS <= atExpLS)
            // console.log('actionOK: ', actionOK)

            if (actTimeLS <= atExpLS) {
                setServiceRT(true)
            }
            else {
                setTimeout(function () {
                    refreshCyR.forceToken(true)
                }, 300)
                setTimeout(function () {
                    setUD(true)
                }, 900)
            }
        }
    }, [uD, actionOK])
    /*-------------------------------------------------*/
    // hace la peticion a servicio
    useEffect(() => {
        // Va al servicio para traer las direcciones
        if(actionOK === 'getPedidos'){
            setServiceRT(false)
            getMisPedidos({ days: pedidos.days, pages: pedidos.pages })
            .then(setResultadosPedidos)
            setActionOk(false)
        }
    }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    // peticion loginAyudaDinamica
    // useEffect(() => {
    //     if (resultadosPedidos) {
    //         LoginAyudaDinamica({ username: USERNAME, password: PASSWORD, publicKey: PUBLICKEY })
    //         .then(getSuccessLogin)
    //     }
    // }, []);

    // obtiene el access token para enviarlo a ayuda dinamica 
    useEffect(() => {
        if (sucessLogin) {
            if (sucessLogin.data)
                setAccessToken(sucessLogin.data.access_token);
        }
    }, [sucessLogin]);

    // muestra loading hasta que cargue la informacion
    const [flagEstadoNoManejado, setFlagEstadoNoManejado] = useState(false);
    // regresar a pedido en modal error
    const regresarPedidos = () => {
        setFlagEstadoNoManejado(false);
        history.replace("/");
    }
    // cerrar modal de error
    const handleCloseError = () => setFlagEstadoNoManejado(false);

    useEffect(() => {
        // console.log(" resultado pedidos----: ", resultadosPedidos)
        if (resultadosPedidos) {
            if (Number(resultadosPedidos?.error) >= 500 || Number(resultadosPedidos?.error) === 400) {
                setLoading(false);
                setFlagEstadoNoManejado(true);

            }
            else if (Object.keys(resultadosPedidos).length > 0) {
                setTimeout(function () {
                    setLoading(false);
                    window.scrollTo(0, 0);
                }, 500)
            }
        }
        else if (resultadosPedidos === undefined || resultadosPedidos === "undefined") {
            setLoading(false);
            setFlagEstadoNoManejado(true);
        }
    }, [resultadosPedidos]);

    const handleSubmit = evt => {
        evt.preventDefault();
    };

    // setea los filtros 
    const handleSelect = evt => {
        window.scrollTo(0, 0);
        setPedidos(
            {
                ...pedidos,
                days: Number(evt.target.value),
                pages: 1
            }
        );

        let nuevaUrl = `/mi-cuenta/mis-pedidos/dias=${Number(evt.target.value)}/pagina=1`;
        history.replace(nuevaUrl);
    };

    const handleSelectMobile = evt => {
        window.scrollTo(0, 0);
        setPedidos(
            {
                ...pedidos,
                days: Number(evt),
                pages: 1
            }
        );

        let nuevaUrl = `/mi-cuenta/mis-pedidos/dias=${Number(evt)}/pagina=1`;
        history.replace(nuevaUrl);
    };

    // regresar a la pagina anterior
    const backHistory = () => {
        history.go(-1);
    };

    // cambiar a la pagina seleccionada
    const cambiarPagina = (paginaNueva) => {
        setpaginaActual(paginaNueva);
        let nuevaUrl = `/mi-cuenta/mis-pedidos/dias=${pedidos.days}/pagina=${paginaNueva}`;
        history.replace(nuevaUrl);
        window.scrollTo(0, 0);
    };

    const pag = [];
    const paginaMapeo = [resultadosPedidos].map((ar, i) => {
        if (ar) {
            var lis = Object.entries(ar);
            lis.map((item, j) => {
                return (item[i] === "pagination"
                    ? pag.push({
                        'total_items': item[1].total_items,
                        "total_pages": item[1].total_pages,
                        "current_page": item[1].current_page,
                        "items_per_page": item[1].items_per_page
                    })
                    : null
                )
            })
        }
    });

    var total_items = pag.map(p => p.total_items); // numero de  pedidos que tiene el usuario
    var items_per_page = pag.map(p => p.items_per_page); //numero de pedidos por pagina (10)

    return (
        <>
            {loading && <Loading />}

            {isMobile
                ? isMobile === 'desktop'
                    ? <MisPedidosTemplateDesktop
                        pedidos={resultadosPedidos}
                        setLoading={setLoading}
                        days={days}
                        setDays={setDays}
                        handleSubmit={handleSubmit}
                        handleSelect={handleSelect}
                        backHistory={backHistory}

                        searchPedidos={pedidos}
                        total_items={total_items}
                        items_per_page={items_per_page}
                        current_page={pag.map(p => p.current_page)}
                        cambiarPagina={cambiarPagina}
                        pag={pag}
                        rangoDias={pedidos.days}
                        setPedidos={setPedidos}
                        history={history}
                    />
                    : isMobile === 'movile'
                        ? null
                        : <MisPedidosTemplateMobile
                            pedidos={resultadosPedidos}
                            setLoading={setLoading}
                            days={days}
                            setDays={setDays}
                            handleSubmit={handleSubmit}
                            handleSelect={handleSelectMobile}
                            backHistory={backHistory}

                            searchPedidos={pedidos}
                            total_items={total_items}
                            items_per_page={items_per_page}
                            current_page={pag.map(p => p.current_page)}
                            cambiarPagina={cambiarPagina}
                            pag={pag}
                            rangoDias={pedidos.days}
                            setPedidos={setPedidos}
                            history={history}
                        />
                : null
            }

            {flagEstadoNoManejado &&
                <ModalPortal type="modalSB" onClose={handleCloseError} >
                    <ModalOcurrioUnError okRegresar={regresarPedidos} />
                </ModalPortal>
            }
            <Footer />
        </>
    )
}