import React, { useState, Fragment } from 'react';
import './style.sass';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { NO_IMAGE, PEDIDOS_PENDIENTES_AUTORIZAR, PEDIDOS_PENDIENTES_PAGO, PEDIDOS_PRODUCTO_ENTREGADO, PEDIDO_CANCELADO, REEMBOLSO } from 'constants/general';
import { repareTextHtml } from 'functions/repareDataService';

export default function CardOrder({ ...props }) {
    const {
        order_number,
        products,
        estatusPedido
    } = props;

    const [, setcopied] = useState(false);
    const [, setvalueCopy] = useState('pedido');
    const [click, setclick] = useState(false);

    const [showModal, SetShowModal] = useState(false);
    const showDetailOrder = () => {
        SetShowModal(true);
    }

    const handleClose = () => {
        SetShowModal(false);
    }

    const onCopy = () => { setcopied(true); }

    const handleClickCopy = () => {
        setclick(!click);
    }

    const handleChangeCopy = (e) => {
        const valor = e.target.value;
        setvalueCopy(valor);
        setcopied(false);
    }

    return (
        estatusPedido === PEDIDOS_PENDIENTES_PAGO || PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido)
            ? <article className="cardOrder">
                <div className="titleCardOrder">
                    <CopyToClipboard text={order_number} onCopy={() => setcopied(true)}>
                        <>
                            <span className="textPedido">Pedido No.</span>
                            <span onClick={showDetailOrder} className="link_enviado">{order_number}</span>
                        </>
                    </CopyToClipboard>

                    <CopyToClipboard text={order_number} onCopy={() => onCopy()}>
                        <button onClick={handleClickCopy} className="botonCopy" />
                    </CopyToClipboard>
                </div>

                {products && products.map((producto, ind) =>
                    <Fragment key={ind}>
                        <div className="dataOrder">

                            <Link to={"/producto/" + producto.id + "/" + String(producto?.name).toLowerCase().replace(/ /g, "-").replace(/%/g, "")}>
                                {producto.images
                                    ? <img src={producto.images[0].url} alt={repareTextHtml(producto?.name)} width="80" height="80" loading="lazy" />
                                    : <img src={NO_IMAGE} width="80" height="80" loading='lazy' alt={repareTextHtml(producto?.name)} />
                                }
                            </Link>

                            <div className="dataInternal">
                                <p className="titleOrder">{repareTextHtml(producto?.name)}</p>
                                {producto.shop && producto.shop.name && <p className="sendBy">Enviado por: <Link to={`/Tienda/${producto?.shop?.idTienda}/${producto?.shop?.name.replace(/[^a-zA-Z 0-9.]+/g, '').replace(/ /ig, '-').replace(/%20/ig, '-')}/`} title={producto?.shop?.name} className="link_enviado">{producto?.shop?.name}</Link>
                                </p>
                                }

                                {/* condicion para mostrar el tooltip en preparando tu producto */}
                                {String(producto?.status).toLowerCase() !== 'preparando tu producto'
                                    ? <p className="statusOrder">{String(producto?.status).charAt(0).toUpperCase()}{String(producto?.status).slice(1).toLowerCase()}</p>
                                    : <p className="textoStatusOrder">{String(producto?.status).charAt(0).toUpperCase()}{String(producto?.status).slice(1).toLowerCase()}
                                        <span className="ayuda tooltip bottom">
                                            <span className="tiptext">El vendedor esta preparando tu producto para que sea enviado.</span>
                                        </span>
                                    </p>
                                }

                                {/* copy entregado */}
                                {(producto.delivery_date.delivery_date !== null && String(producto.status).toLowerCase() === "entregado") && <p className="date">el {producto.delivery_date.delivery_date}</p>}

                                {/* copy fecha estimada de entrega */}
                                {(producto.delivery_date.estimated_delivery_date !== null &&
                                    (String(producto.status).toLowerCase() === "en camino" ||
                                        String(producto.status).toLowerCase() === "preparando tu producto" ||
                                        String(producto.status).toLowerCase() === "autorizado" ||
                                        String(producto.status).toLowerCase() === "en proceso de recolección" ||
                                        String(producto.status).toLowerCase() === "listo para pagar y recoger" ||
                                        String(producto.status).toLowerCase() === 'pedido autorizado'
                                    )) &&
                                    <p className="date">{producto.delivery_date.estimated_delivery_date}</p>
                                }



                            </div>
                        </div>

                        <Link to={"/mi-cuenta/mis-pedidos/" + order_number} className="detailCardOrderLink">
                            <div className="detailCardOrderButton">Ver detalle</div>
                        </Link>
                    </Fragment>
                )}

                <>
                    {/* pedido pendiente de autorizar o pendiente de pago */}
                    {(PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) || PEDIDOS_PENDIENTES_PAGO === estatusPedido)
                        && <Link to={"/mi-cuenta/mis-pedidos/" + order_number + "/necesitas-ayuda"} className="detailCardOrderLink">
                            <div className="detailCardOrder">Cancelar</div>
                        </Link>
                    }
                </>
            </article>
            : <article className="cardOrder">
                <div className="titleCardOrder">
                    <CopyToClipboard text={order_number} onCopy={() => setcopied(true)}>
                        <>
                            <span className="textPedido">Pedido No.</span>
                            <span onClick={showDetailOrder} className="link_enviado">{order_number}</span>
                        </>
                    </CopyToClipboard>

                    <CopyToClipboard text={order_number} onCopy={() => onCopy()}>
                        <button onClick={handleClickCopy} className="botonCopy" />
                    </CopyToClipboard>
                </div>

                {products && products.map((producto, ind) =>
                    <Fragment key={ind}>
                        <div className="dataOrder">
                            <Link to={"/producto/" + producto.id + "/" + String(producto.name).toLowerCase().replace(/ /g, "-").replace(/%/g, "")}>
                                {producto.images
                                    ? <img src={producto.images[0].url} alt={repareTextHtml(producto?.name)} width="80" height="80" loading="lazy" />
                                    : <img src={NO_IMAGE} width="80" height="80" loading='lazy' alt={repareTextHtml(producto?.name)} />
                                }
                            </Link>

                            <div className="dataInternal">
                                <p className="titleOrder">{repareTextHtml(producto?.name)}</p>
                                {producto.shop && producto.shop.name
                                    && <p className="sendBy">Enviado por:  <Link to={`/Tienda/${producto?.shop?.idTienda}/${producto?.shop?.name.replace(/[^a-zA-Z 0-9.]+/g, '').replace(/ /ig, '-').replace(/%20/ig, '-')}/`} title={producto?.shop?.name} className="link_enviado">{producto?.shop?.name}</Link>
                                    </p>
                                }

                                {/* condicion para mostrar el tooltip en preparando tu producto */}
                                {String(producto?.status).toLowerCase() !== 'preparando tu producto'
                                    ? <p className={
                                        (String(producto?.status).toLowerCase() === "entregado" || String(producto?.status).toLowerCase() === "en camino" || String(producto?.status).toLowerCase() === "reembolsado")
                                            ? "statusOrderGreen" : "statusOrder"}>{String(producto?.status).charAt(0).toUpperCase()}{String(producto?.status).slice(1).toLowerCase()}</p>

                                    : <p className="textoStatusOrder">{String(producto?.status).charAt(0).toUpperCase()}{String(producto?.status).slice(1).toLowerCase()}
                                        <span className="ayuda tooltip bottom">
                                            <span className="tiptext">El vendedor esta preparando tu producto para que sea enviado.</span>
                                        </span>
                                    </p>
                                }

                                {/* copy entregado */}
                                {(producto?.delivery_date.delivery_date != null && String(producto.status).toLowerCase() === "entregado") && <p className="date">el {producto?.delivery_date?.delivery_date}</p>}

                                {/* copy fecha estimada de entrega */}
                                {(producto.delivery_date.estimated_delivery_date !== null &&
                                    (String(producto.status).toLowerCase() === "en camino" ||
                                        String(producto.status).toLowerCase() === "preparando tu producto" ||
                                        String(producto.status).toLowerCase() === "autorizado" ||
                                        String(producto.status).toLowerCase() === "en proceso de recolección" ||
                                        String(producto.status).toLowerCase() === "listo para pagar y recoger" ||
                                        String(producto.status).toLowerCase() === 'pedido autorizado'
                                    )) &&
                                    <p className="date">{producto?.delivery_date?.estimated_delivery_date}</p>
                                }

                            </div>
                        </div>


                        {/* seccion rastreo de paquete tracking_link*/}
                        {((String(producto.tracking.tracking_link) !== null && String(producto.tracking.tracking_link) !== "null")
                            && String(producto.status).toLowerCase() !== "reembolsado" &&
                            String(producto.status).toLowerCase() !== "en proceso de reembolso" &&
                            String(producto.status).toLowerCase() !== "cancelación solicitada" &&
                            String(producto.status).toLowerCase() !== "no autorizado" &&
                            String(producto.status).toLowerCase() !== "no pagado") ?
                            <div className="boxRastreo">
                                <div className="envioBox">
                                    <a className="textoRastrear" href={producto.tracking.tracking_link} target="_blank" rel="noopener noreferrer">Rastrear paquete</a>

                                    <div className="boxTrackingPaqueteria">
                                        <div className="copiarTracking">
                                            <CopyToClipboard text={producto.tracking.code} onCopy={() => setcopied(true)}>
                                                <>
                                                    <p className="numguia">No. de Guía: <span onClick={showDetailOrder}>{producto.tracking.code}</span></p>
                                                </>
                                            </CopyToClipboard>

                                            <CopyToClipboard text={producto.tracking.code} onCopy={() => onCopy()}>
                                                <button onClick={handleClickCopy} className="botonCopy" />
                                            </CopyToClipboard>
                                        </div>

                                        <p className="paqueteria">{producto.tracking.company_name}</p>
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                        {/* boton volver a comprar */}
                        {(PEDIDOS_PRODUCTO_ENTREGADO.includes(producto.status_id)) &&
                            <Link to={"/producto/" + producto.id + "/" + String(producto.name).toLowerCase().replace(/ /g, "-").replace(/%/g, "")} className="detailCardOrderLink">
                                <div className="detailCardOrderButton">Volver a comprar</div>
                            </Link>
                        }

                        <Link to={"/mi-cuenta/mis-pedidos/" + order_number} className="detailCardOrderLink">
                            <div className={(REEMBOLSO.includes(String(producto.status).toLowerCase()) || PEDIDO_CANCELADO === Number(estatusPedido)) ? "detailCardOrder" : "detailCardOrderButton"}>Ver detalle</div>
                        </Link>

                        <>
                            {/* pedido pendiente de autorizar o pendiente de pago */}
                            {producto?.shipping_method !== "Click y recoge" ?
                                (PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) || PEDIDOS_PENDIENTES_PAGO === estatusPedido)
                                && <Link to={"/mi-cuenta/mis-pedidos/" + order_number + "/necesitas-ayuda"} className="detailCardOrderLink">
                                    <div className="detailCardOrder">Cancelar</div>
                                </Link>
                                :null
                            }

                            {/* PEDIDOS DIFERENTES DE: PENDIENTE DE PAGO, PENDIENTE DE AUTORIZAR, ENTREGADO, CANCELADO, REEMBOLSO */}
                            {producto?.shipping_method !== "Click y recoge" ?
                                !PEDIDOS_PENDIENTES_AUTORIZAR.includes(estatusPedido) && PEDIDOS_PENDIENTES_PAGO !== estatusPedido
                                    && (!PEDIDOS_PRODUCTO_ENTREGADO.includes(producto.status_id))
                                    && PEDIDO_CANCELADO !== Number(estatusPedido) && !REEMBOLSO.includes(String(producto.status).toLocaleLowerCase()) && (producto.dynamic_help?.can_returned === true)
                                    && <Link to={"/mi-cuenta/mis-pedidos/" + order_number + "/product=" + producto.related_product_id + "/necesitas-ayuda"} className="detailCardOrderLink">
                                        <div className="detailCardOrder">¿Necesitas ayuda?</div>
                                    </Link>
                                :null
                            }

                            {producto?.dynamic_help?.limit_days > 0 && producto?.shipping_method !== "Click y recoge" ? 
                                (PEDIDOS_PRODUCTO_ENTREGADO.includes(producto.status_id) && producto.features.digital === false && (producto.dynamic_help?.can_returned === true)) &&
                                    <Link to={"/mi-cuenta/mis-pedidos/" + order_number + "/product=" + producto.related_product_id + "/necesitas-ayuda"} className="detailCardOrderLink">
                                        <div className="detailCardOrderButton">¿Necesitas ayuda?</div>
                                    </Link>
                                :null
                            }

                            {/* boton para descargar guia */}
                            {/* {console.log("--------- ", producto?.dynamic_help?.have_ticket?.details?.tracking?.link)} */}
                            {/* PEDIDOS_PRODUCTO_ENTREGADO.includes(producto.status_id) && */}
                            {( producto.features.digital === false && (producto?.dynamic_help?.have_ticket?.details?.tracking))
                                ? <a href={producto?.dynamic_help?.have_ticket?.details?.tracking?.link} className="detailCardOrderLink"  target="_blank" rel="noopener noreferrer">
                                    <div className="detailCardOrderButton">Descargar guía</div>
                                </a>
                                : null
                            }
                            {/* verificacion de pedido cancelado o reembolsado para redirigir a faqs */}
                            {(PEDIDO_CANCELADO === Number(estatusPedido) || REEMBOLSO.includes(String(producto.status).toLocaleLowerCase()))
                                && null
                            }
                        </>
                    </Fragment>
                )}
            </article>

    )
}