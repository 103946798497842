import React, { useEffect, useState } from 'react';
import Header from 'pages/Header/HeaderNavegacion';
import BarraCP from 'components/BarCP/BarHeaderCP';
import { decodeStorage } from 'functions/storageBase64';
import Footer from 'pages/Footer/FooterDesktop';
import { MenuPoliticas } from 'components/MenuAutocenter/menuAutocenter';
import "./style.sass";
import { Link, useHistory } from 'react-router-dom';
import { useIsMobile } from 'hooks/useIsMobile';

export function AvisoAutocenterDesktop() {
    const codigoP = decodeStorage('codigoPostal');
    const [codigoPostal, setCodigoPsotal] = useState(codigoP);

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Autocenter Page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //setLoading(false)
    }, [codigoPostal])

    //recibe la direccion del CP
    const [updateCPBar, setUpdateCPBar] = useState();
    const changeDirectionBar = (val) => {
        setCodigoPsotal(val);
        setUpdateCPBar(val);
    };
    const [upCP, setUpCP] = useState();
    useEffect(() => {
        if (upCP) {
            //console.log('')
        }
    }, [upCP, updateCPBar])

    return (
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={changeDirectionBar} />
            <section className="barCpResponsive">
                <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
            </section>

            <main>
                <section className="sectionAuto">
                    <div className="sectionMenu">
                        <MenuPoliticas />
                    </div>

                    <div className="sectionContent">
                        <section className="sectionTitleAuto">
                            <div className="divTitle">
                                <p>Aviso de Privacidad Autocenter</p>
                            </div>
                        </section>

                        <div className="contentPreguntas">
                            <div className="question1">
                                <h2>¿Quién dará tratamiento a sus datos personales?</h2>
                                <p>Sears Operadora México S.A. de C.V., (en adelante Sears) con domicilio en Lago Zúrich No. 245, Edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p>Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            </div>

                            <div className="question1">
                                <h2>¿Qué información vamos a recabar?</h2>
                                <p>Sears para cumplir con las finalidades señaladas en el presente Aviso tratará datos personales de identificación, datos personales de contacto, datos personales de ubicación, datos fiscales, datos personales patrimoniales y/o financieros, información sobre su vehículo, e información que usted nos proporcione de manera voluntaria para la atención de quejas, reclamaciones o solicitudes de información.</p>
                                <p>Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sears dará tratamiento a datos personales de terceros en calidad de referencias personales, por lo que al proporcionar los datos personales de dichos terceros para que Sears les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable.</p>
                                <p>Sears no recabará ni tratará datos personales sensibles. En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos.</p>
                            </div>

                            <div className="question1">
                                <h2>¿Para qué fines usamos su información?</h2>
                                <p>Sears tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Para identificarle como usuario de Auto Center;</li>
                                    <li>Llevar un registro de los servicios que usted ha contratado con Auto Center;</li>
                                    <li>Identificarle y contactarlo a través de distintos medios de comunicación tanto físicos como electrónicos para dar cumplimiento a las obligaciones contraídas;</li>
                                    <li>Acreditar su identidad y validar la veracidad y calidad de la información que usted nos proporciona;</li>
                                    <li>Atender y resolver sus dudas relacionadas con respecto a los productos y/o servicios que ofrece Auto Center;</li>
                                    <li>Llevar a cabo el servicio de mantenimiento, reparación y/o verificación de su automóvil que usted haya contratado con Sears;</li>
                                    <li>Para llevar a cabo el ingreso de su vehículo a Auto Center para realizar los servicios que usted haya contratado con Sears;</li>
                                    <li>En su caso, para agendar la recolección de su vehículo en el lugar que usted nos indique.</li>
                                    <li>Para realizarle la entrega de su vehículo una vez realizado el servicio que usted haya contratado con Sears;</li>
                                    <li>En caso de que usted lo haya contratado, para realizar el trámite de verificación de su vehículo;</li>
                                    <li>Para informarle cualquier hallazgo relacionado con su vehículo durante la realización del servicio que usted haya contratado con Sears;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sears considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros.</li>
                                    <li>Atender las solicitudes de compra remitidas y procesar pagos;</li>
                                    <li>Gestiones de cobranza;</li>
                                    <li>Realizar aclaraciones bancarias;</li>
                                    <li>Realizar consultas, investigaciones y revisiones para atender sus quejas, reclamaciones y/o sugerencias;</li>
                                    <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos;</li>
                                    <li>Para las gestiones de facturación relacionadas con los productos adquiridos;</li>
                                    <li>Realizar actividades de monitoreo de calidad en los servicios o sobre su experiencia de compra con nosotros;</li>
                                    <li>Hacer efectivas las garantías, reparaciones y/o servicios de mantenimiento cuando resulte procedente;</li>
                                    <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta como cliente preferido y enviarle comunicaciones electrónicas personalizadas de acuerdo a sus intereses;</li>
                                    <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted.</li>
                                </ul>
                            </div>

                            <div className="question1">
                                <h2>¿Qué otros usos podemos darle a su información?</h2>
                                <p>De manera adicional, si usted no se opone, Sears podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>

                                <ul className="ulQuestionDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de Sears de acuerdo con sus intereses, así como de otras empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos.</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sears y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p>En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                                <p>Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sears.</p>
                                <p>La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>
                            </div>

                            <div className="question1">
                                <h2>¿A qué terceros podemos transferir su información?</h2>
                                <p>Sears para cumplir la(s) finalidad(es) anteriormente descrita(s) transferirá sin requerir de tu consentimiento los datos necesarios a terceros tanto nacionales como extranjeros, intervinientes en la prestación de los productos y/o servicios y forma de pago, solicitados por Usted.</p>
                                <p>Sears podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p>Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en <a href="http://www.gsanborns.com.mx/perfil-corporativo.html" target="_blank" rel="noopener noreferrer">http://www.gsanborns.com.mx/perfil-corporativo.html</a></p>
                                <p>En caso de que las operaciones que usted realice con Sears, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sears, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p>Sears podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p>Asimismo, le informamos que en el supuesto de que usted incumpla con las obligaciones de pago contraídas, sus datos personales podrán ser transferidos a las Sociedades de Información Crediticia con las que tengamos celebrados acuerdos de comunicación de datos sin requerir su consentimiento por ser una obligación legal en términos de la Ley respectiva.</p>
                                <p>Si usted no se opone, Sears podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>
                                <p>En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</h2>
                                <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>.</p>
                                <p>Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sears pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sears.</p>
                                <p>En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo puede limitar el uso y/o divulgación de sus datos?</h2>
                                <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sears. Para mayor información favor de contactar al Departamento de Protección de Datos de Sears.</p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</h2>
                                <p>Sears le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">https://www.sears.com.mx</Link>, por lo cual le pedimos revise la misma de manera periódica.</p>
                            </div>

                            <div className="question1">
                                <h2>¿Cómo puede hacernos llegar sus dudas?</h2>
                                <p>Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                            </div>

                            <div className="question1">
                                <h2>INAI</h2>
                                <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>
                            </div>

                            <div className="question1">
                                <h2>Aceptación de Aviso de Privacidad</h2>
                                <p>Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último.</p>
                                
                                <p className="ps">Fecha de última actualización: Junio de 2022</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />

        </>
    )
}

export function AvisoAutocenterMobile() {

    const history = useHistory();
    const isNativeApp = window.location.search.indexOf('isNativeApp=true')

    // cerrar modal
    const onClose = () => {
        // history.goBack(-1);
        window.location.href = "/acerca-sears"
    }

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Autocenter page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="modalDetallePedido full">
                <div className="contentModal">

                    {isNativeApp < 0
                        ?
                            <div className="contentCloseAN">
                                <div className="btn closeBack" onClick={onClose} ></div>
                                <p className="title">Aviso de Privacidad Autocenter</p>
                            </div>
                        : null
                    }
                    <div className="dataModal">

                        <div className="boxAN">
                            <div className="bxSlider">
                                <div className="contenedorImagenes">
                                    <ul>
                                        <img src="/img/logo-sears.svg" alt={"logo-sears"} width={100} />
                                    </ul>
                                </div>

                                <p className="pSliderTitleLred">¿Quién dará tratamiento a sus datos personales?</p>
                                <p className="pSliderL">Sears Operadora México S.A. de C.V., (en adelante Sears) con domicilio en Lago Zúrich No. 245, Edificio Presa Falcón piso 7, colonia Ampliación Granada, alcaldía Miguel Hidalgo, C.P. 11529, Ciudad de México, será el responsable del tratamiento de sus datos personales.</p>
                                <p className="pSliderL">Para cualquier duda relacionada con la protección de sus datos personales podrá contactar a nuestro Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>

                                <p className="pSliderTitleLred">¿Qué información vamos a recabar?</p>
                                <p className="pSliderL">Sears para cumplir con las finalidades señaladas en el presente Aviso tratará datos personales de identificación, datos personales de contacto, datos personales de ubicación, datos fiscales, datos personales patrimoniales y/o financieros, información sobre su vehículo, e información que usted nos proporcione de manera voluntaria para la atención de quejas, reclamaciones o solicitudes de información.</p>
                                <p className="pSliderL">Asimismo, le informamos que para efectos de lo señalado en el presente Aviso de Privacidad Sears dará tratamiento a datos personales de terceros en calidad de referencias personales, por lo que al proporcionar los datos personales de dichos terceros para que Sears les contacte por medios físicos y/o electrónicos usted reconoce haber recabado el consentimiento requerido en términos de la normatividad aplicable. </p>

                                <p className="pSliderL">Sears no recabará ni tratará datos personales sensibles. En caso de que usted nos contacte por redes sociales, podremos recabar los datos de su perfil público de redes sociales para darle atención a sus peticiones y/o solicitudes de información en relación con los productos que ofrecemos.</p>

                                <p className="pSliderTitleLred">¿Para qué fines usamos su información?</p>
                                <p className="pSliderL">Sears tratará sus datos personales para las siguientes finalidades primarias y necesarias:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Para identificarle como usuario de Auto Center;</li>
                                    <li>Llevar un registro de los servicios que usted ha contratado con Auto Center;</li>
                                    <li>Identificarle y contactarlo a través de distintos medios de comunicación tanto físicos como electrónicos para dar cumplimiento a las obligaciones contraídas;</li>
                                    <li>Acreditar su identidad y validar la veracidad y calidad de la información que usted nos proporciona;</li>
                                    <li>Atender y resolver sus dudas relacionadas con respecto a los productos y/o servicios que ofrece Auto Center;</li>
                                    <li>Llevar a cabo el servicio de mantenimiento, reparación y/o verificación de su automóvil que usted haya contratado con Sears;</li>
                                    <li>Para llevar a cabo el ingreso de su vehículo a Auto Center para realizar los servicios que usted haya contratado con Sears;</li>
                                    <li>En su caso, para agendar la recolección de su vehículo en el lugar que usted nos indique.</li>
                                    <li>Para realizarle la entrega de su vehículo una vez realizado el servicio que usted haya contratado con Sears;</li>
                                    <li>En caso de que usted lo haya contratado, para realizar el trámite de verificación de su vehículo;</li>
                                    <li>Para informarle cualquier hallazgo relacionado con su vehículo durante la realización del servicio que usted haya contratado con Sears;</li>
                                    <li>Para contactar a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos, servicios de mensajería instantánea (WhatsApp, Telegram, entre otros que Sears considere convenientes). Lo anterior, para realizar actividades de contacto con el propósito de brindarle información sobre los Servicios que ofrecemos, informarle sobre cualquier beneficio al que se hubiere hecho acreedor como consecuencia de los mismos y dar cumplimiento a las obligaciones que usted haya adquirido con nosotros.</li>
                                    <li>Atender las solicitudes de compra remitidas y procesar pagos;</li>
                                    <li>Gestiones de cobranza;</li>
                                    <li>Realizar aclaraciones bancarias;</li>
                                    <li>Realizar consultas, investigaciones y revisiones para atender sus quejas, reclamaciones y/o sugerencias;</li>
                                    <li>Dar cumplimiento a los requerimientos de autoridades competentes en los casos legalmente previstos;</li>
                                    <li>Para las gestiones de facturación relacionadas con los productos adquiridos;</li>
                                    <li>Realizar actividades de monitoreo de calidad en los servicios o sobre su experiencia de compra con nosotros;</li>
                                    <li>Hacer efectivas las garantías, reparaciones y/o servicios de mantenimiento cuando resulte procedente;</li>
                                    <li>En el supuesto de que usted cumpla con los requisitos establecidos por Sears para darle de alta como cliente preferido y enviarle comunicaciones electrónicas personalizadas de acuerdo a sus intereses;</li>
                                    <li>Dar cumplimiento a las políticas y procedimientos de carácter interno;</li>
                                    <li>Dar cumplimiento a las obligaciones derivadas de la relación jurídica con usted.</li>
                                </ul>

                                <p className="pSliderTitleLred">¿Qué otros usos podemos darle a su información?</p>
                                <p className="pSliderL">De manera adicional, si usted no se opone, Sears podrá mantener su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención:</p>
                                <ul className="ulDeclaracionesDisc">
                                    <li>Informarle del lanzamiento o cambios de nuevos productos, bienes, servicios, promociones y/u ofertas de Sears de acuerdo con sus intereses, así como de otras empresas del Grupo incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, mensajes cortos y demás medios de comunicación físicos y/o electrónicos.</li>
                                    <li>Posibles contactos posteriores para ofrecimiento de productos, bienes y/o servicios que ofrezca Sears y/o Empresas del Grupo;</li>
                                    <li>Compartir sus perfiles y hábitos de consumo con nuestros socios comerciales y/o Empresas del Grupo para que éstas puedan contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideren de su interés y/o para que puedan valorar, conforme a sus procedimientos internos, si usted es susceptible de obtener el servicio que hubiere solicitado ante dicho tercero;</li>
                                    <li>Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele-marketing entre otras.</li>
                                </ul>

                                <p className="pSliderL">En caso de que no desee que sus datos personales sean tratados para alguna o todas las finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>
                                <p className="pSliderL">Adicionalmente, te informamos que tú puedes en todo momento cancelar el envío de mensajes promocionales haciendo clic en el enlace Cancelar Suscripción que está integrado en el pie de página de todos los correos electrónicos enviados por Sears.</p>
                                <p className="pSliderL">La negativa para el uso de sus datos personales para fines adicionales, no podrá ser un motivo para negarle los servicios solicitados o dar por terminada la relación establecida con nosotros.</p>

                                <p className="pSliderTitleLred">¿A qué terceros podemos transferir su información?</p>
                                <p className="pSliderL">Sears para cumplir la(s) finalidad(es) anteriormente descrita(s) transferirá sin requerir de tu consentimiento los datos necesarios a terceros tanto nacionales como extranjeros, intervinientes en la prestación de los productos y/o servicios y forma de pago, solicitados por Usted.</p>
                                <p className="pSliderL">Sears podrá transferir, sin requerir de su consentimiento, los datos personales necesarios a autoridades competentes, tanto nacionales como internacionales, en los casos legalmente previstos, así como a cualquier empresa Subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común, las cuales operan bajo políticas de privacidad que cumplen con las disposiciones aplicables correspondientes, para dar cumplimiento a las finalidades señaladas en el presente aviso.</p>
                                <p className="pSliderL">Para conocer cuáles son las Empresa subsidiaria, afiliada, parte relacionada, controlada, controladora o bajo control Común a quienes sus datos son transferidos puede consultarlo en <a href="http://www.gsanborns.com.mx/perfil-corporativo.html" target="_blank" rel="noopener noreferrer">http://www.gsanborns.com.mx/perfil-corporativo.html</a></p>
                                <p className="pSliderL">En caso de que las operaciones que usted realice con Sears, y las cuales estén clasificadas en Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, como actividades vulnerables, Sears, podrá entregar determinada información de usted sin requerir de su consentimiento a las autoridades fiscales competentes con la finalidad de dar cumplimiento a lo dispuesto por la Ley.</p>
                                <p className="pSliderL">Sears podrá transferir  sin requerir de su consentimiento los datos personales necesarios  a:  socios comerciales y/o terceros con los que tengamos celebrados contratos de prestación de servicios para prestar servicios de entrega de bienes y productos, postventa, gestionar cuentas y registros de clientes, enviar comunicación relativa a productos o servicios que pueden ser de su interés, programas de lealtad, así como comunicaciones de marketing, con los que tengamos celebrados acuerdos comerciales o de colaboración para fines relacionados con la validación y autenticación de su identidad, conforme a las finalidades descritas en el presente Aviso de Privacidad.</p>
                                <p className="pSliderL">Asimismo, le informamos que en el supuesto de que usted incumpla con las obligaciones de pago contraídas, sus datos personales podrán ser transferidos a las Sociedades de Información Crediticia con las que tengamos celebrados acuerdos de comunicación de datos sin requerir su consentimiento por ser una obligación legal en términos de la Ley respectiva.</p>
                                <p className="pSliderL">Si usted no se opone, Sears podrá transferir sus datos personales a socios comerciales para que estos puedan contactarle y ofrecerle bienes y/o servicios que resulten de su interés, o bien, para que puedan determinar si usted es sujeto a adquirir los bienes y/o servicios que dichos terceros ofrecen.</p>
                                <p className="pSliderL">En caso de que no desee que sus datos personales sean transferidos para alguna o todas las finalidades señaladas, desde este momento usted nos puede comunicar lo anterior al correo <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>

                                <p className="pSliderTitleLred">¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</p>
                                <p className="pSliderL">Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Protección de Datos de Sears a la dirección electrónica <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>.</p>
                                <p className="pSliderL">Su petición deberá ser realizada a través del Formato de Solicitud de Derechos ARCO, el cual debe ser solicitado a la dirección de correo electrónico antes señalada. Para que el Departamento de Protección de Datos de Sears pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en el Formato y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo. En caso de que la información proporcionada sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, nuestro Departamento de Protección de Datos, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente. Nuestro Departamento de Protección de Datos le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. Para obtener mayor información sobre los procedimientos, requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocación del consentimiento contacte al Departamento de Protección de Datos de Sears.</p>
                                <p className="pSliderL">En caso de que su solicitud de cancelación, oposición o revocación sea procedente, según corresponda se dejarán de usar sus datos, para la finalidad que nos hubiere requerido o se eliminarán de nuestros sistemas en términos de lo que establece la legislación de la materia.</p>

                                <p className="pSliderTitleLred">¿Cómo puede limitar el uso y/o divulgación de sus datos?</p>
                                <p className="pSliderL">Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la dirección <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a>. En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión propio de Sears. Para mayor información favor de contactar al Departamento de Protección de Datos de Sears.</p>

                                <p className="pSliderTitleLred">¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de Privacidad?</p>
                                <p className="pSliderL">Sears le notificará de cualquier cambio a su aviso de privacidad a través de nuestra página de internet <Link to="/">https://www.sears.com.mx</Link>, por lo cual le pedimos revise la misma de manera periódica.</p>

                                <p className="pSliderTitleLred">¿Cómo puede hacernos llegar sus dudas?</p>
                                <p className="pSliderL">Nuestro Departamento de Protección de Datos queda a sus órdenes para proporcionarle cualquier información adicional que requiera o, en su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y protección de datos personales, para lo que podrá contactarnos a través del correo electrónico <a href="mailto:arco@sears.com.mx">arco@sears.com.mx</a></p>

                                <p className="pSliderTitleLred">INAI</p>
                                <p className="pSliderL">En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para hacer efectivos sus derechos.</p>

                                <p className="pSliderTitleLred">Aceptación de Aviso de Privacidad</p>
                                <p className="pSliderL">Al hacer uso de nuestros servicios y proporcionar sus datos personales, significa que usted ha leído, entendido, y acordado los términos expuestos en el presente Aviso de Privacidad y consiente el tratamiento de sus datos personales según lo previsto en este último.</p>

                                <p className="pSliderL">Fecha de última actualización: Junio de 2022</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}


export function AvisoAutocenter() {
    const { isMobile } = useIsMobile();

    //- Codigo de instana para track de pagina 
    useEffect(() => {
        window.ineum('page', 'Aviso de Privacidad Autocenter page');
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            { isMobile &&
                isMobile === 'desktop'
                ? <AvisoAutocenterDesktop />

                : isMobile === 'movile'
                    ? null
                    : <AvisoAutocenterMobile />
            }
        </>
    )
}