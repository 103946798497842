import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import "./style.sass";

export function MenuAutocenter() {

    return (
        <nav className="menuAutocenter">
            <ul className="ulAuto">
                <li className="liAuto alone">
                    <p>Déjanos ayudarte</p>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/dudas-comentarios" activeClassName="active">Dudas y Comentarios</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/formas-pago" activeClassName="active">Formas de pago</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/preguntas-frecuentes" activeClassName="active">Preguntas Frecuentes</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/auto-center" activeClassName="active">AutoCENTER</NavLink>
                </li>
                <li className="liAuto alone">
                    <a href="https://serviciosgfi.inbursa.com:4070/multiplataforma/mvc/others/autotalPublico/init?ca=PUBLICO&pa=P&pro=SEARS#no-back-button" target="_blank" rel="noopener noreferrer">
                        Autotal Inbursa
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export function MenuPoliticas() {

    return (
        <nav className="menuAutocenter">
            <ul className="ulAuto">
                <li className="liAuto alone">
                    <p>Políticas Sears</p>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/terminos-condiciones" activeClassName="active" >Términos y Condiciones Paperless</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/terminos-condiciones-market-place" activeClassName="active">Términos y Condiciones Market Place</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad" activeClassName="active" >Aviso de Privacidad Clientes</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-prospectos" activeClassName="active">Aviso de Privacidad Prospectos</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/tarjeta-magica" activeClassName="active">Aviso de Privacidad Tarjeta Mágica SEARS</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-auto-center" activeClassName="active">Aviso de Privacidad Autocenter</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/aviso-privacidad-mesa-de-regalos" activeClassName="active">Aviso de Privacidad Mesa de Regalos</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/politica-cancelacion" activeClassName="active">Política Cancelación</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/politica-devolucion" activeClassName="active">Política de Devolución</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/politica-envio" activeClassName="active">Política de Envío</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/politica-compra" activeClassName="active">Política de Compra</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/servicio-de-conexion/" activeClassName="active">Servicio de Conexión</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export function MenuNuestraCompania() {
    return (
        <nav className="menuAutocenter">
            <ul className="ulAuto">
                <li className="liAuto alone">
                    <p>Nuestra Compañía</p>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/acerca-nosotros" activeClassName="active">Acerca de Nosotros</NavLink>
                </li>

                <li className="liAuto alone">
                    <NavLink to="/localizador-tiendas" activeClassName="active">Localizador de Tiendas</NavLink>
                </li>

                <li className="liAuto alone">
                    <a href="http://facturaelectronica.sears.com.mx/">Facturación Electrónica</a>
                </li>

                <li className="liAuto alone">
                     <a href="/Mesa-de-Regalos/" title="Mesa de Regalos">Mesa de Regalos</a>
                    {/* <Link to="/Mesa_Regalos?utm_source=direct&utm_medium=menu-superior-home-sears-com&utm_campaign=mesa_de_regalos">Mesa de Regalos</Link> */}
                </li>
            </ul>
        </nav>
    )
}