import React, {useState, useEffect} from 'react'
import './style.sass'
import { Helmet } from "react-helmet"
import {Link} from 'react-router-dom'
import { useIsMobile } from 'hooks/useIsMobile'
import { dataLayerHome } from 'functions/dataLayerFunction'
import { removeStorage } from 'functions/storageBase64'
import { getHomeNew } from 'services/Home'


import Header from 'pages/Header/HeaderNavegacion'
import Loading from 'components/Loading'
import BarraCP from 'components/BarCP/BarHeaderCP'
import { HomeTemplateDesktopNew } from 'pages/Home/HomeDesktop'
import { HomeTemplateMobileNew } from 'pages/Home/HomeMobile'
import { DownloadApp } from 'components/DownloadApp'
import {ErrorBoundary} from 'components/Error/ErrorBoundary'

import { useCart } from 'hooks/useCart'


function Home() {

	//- Codigo de instana para track de pagina 
    useEffect(()=>{
        window.ineum('page', 'Home Page');
		window.scrollTo(0, 0)
		window.ineum('wrapEventHandlers', true);
		removeStorage('modalPostalCode')

		removeStorage('wishlistAfter')
        removeStorage('productAfter')
        removeStorage('typeButton')
		removeStorage("storeSelec")
		removeStorage('searchBusqueda')
	
    },[])

	const { 
		cartCount, 
		carrito,
		Product_Add,
		Product_Update,
		Product_Delete
	  } = useCart({firstActive: true, name: "HomePage", timeRef: 1000})

	  useEffect(()=>{
		// Product_Delete([{
		//   id_product: "0",
		//   children_sku: "0",
		//   quantity: "1"
		// }])
	
		if(cartCount){
		  console.log('cartCount: ',cartCount)
		}
		if(carrito){
		  console.log('carrito: ',carrito)
		}
	  },[cartCount, carrito])
	

	let SS_respHomeV3 = window.sessionStorage.getItem('respHomeV3')

	const { isMobile } = useIsMobile()
	const [ loading, setLoading ] = useState(false)
	const [ errors, setErrors ] = useState('')
	const [respHomeV3, setRespHomeV3] = useState( SS_respHomeV3 ? JSON.parse( SS_respHomeV3 ) : null  )
	const [responseM, setResponseM] = useState()


	
	useEffect(() => {
		if(isMobile){
			if(isMobile === 'desktop'){
				getHomeNew()
				.then(setResponseM)
			}else{
				getHomeNew()
				.then(setResponseM)
			}
		}
	},[isMobile])

	const [dlContent, setDlContent] = useState(false)

	//- Ajsuta la respuesta del servicio home V3
	useEffect(() => {
		if(responseM){
			if(responseM.statusCode === 200){
	
				if(typeof(responseM.data) === 'object'){
					if(responseM?.data?.data?.homeItems){
						//console.log('responseWeb?.data?.data?.homeItems: ', responseM?.data?.data?.homeItems)
						setRespHomeV3(responseM?.data?.data?.homeItems)
						window.sessionStorage.setItem('respHomeV3', JSON.stringify(responseM?.data?.data?.homeItems) )						
						dataLayerHome(responseM?.data?.data?.homeItems)
						setDlContent(responseM?.data?.data?.homeItems)
					}
				}
				else if(typeof(responseM.data) === 'string'){
					setErrors({'error': responseM.statusCode ,'responseM': responseM.data})
					setLoading(false)
				}
			}
			else if(responseM.statusCode === 401){
				setErrors({'error': 404 ,'responseM': responseM.data})
				setLoading(false)
			}
			else{
				setErrors({'error': responseM.statusCode ,'responseM': responseM.data})
				setLoading(false)
			}
			window.scrollTo(0, 0)
		}
	},[responseM])


	//recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{
		setUpdateCPBar(val)
	}
	const [upCP, setUpCP] = useState()
	useEffect(()=>{
		if(upCP){
			//console.log('')
		}
	},[upCP, updateCPBar])

	let structDataOrganization = `{
		"@context":"https://schema.org",
		"@type":"Organization",
		"url" : "https://www.sears.com.mx",
		"image": "https://www.sears.com.mx/img/logo-sears.svg",
		"brand": "SEARS",
		"name": "SEARS OPERADORA MEXICO, S.A. DE C.V.)",
		"legalName": "SEARS OPERADORA MEXICO, S.A. DE C.V.)",
		"slogan": "SEARS me entiende",
		"email": "sears.internet@sears.com.mx",
		"telephone": "(52) 55 54471407",
		"logo": {
		  "@context": "https://schema.org",
		  "@type": "ImageObject",
		  "contentUrl" : "https://www.sears.com.mx/img/logo-sears.svg"
		},
		"address": {
		  "@type": "PostalAddress",
		  "addressLocality": "Ciudad de México, México",
		  "postalCode": "04500",
		  "streetAddress": "Periferico Sur 4690, Jardines del Pedregal de San Ángel, Coyoacán"
		},
		"foundingDate": "1947",
		"foundingLocation": "México",
		"parentOrganization": "Grupo sears",
		"hasMerchantReturnPolicy": "Podrás devolver cualquier artículo comprado en Sears Internet por las siguientes causas: Si el artículo presenta defectos de fabricación, Si existe equivocación en el artículo enviado, conservando la envoltura original de celofán (emplaye) y sin presentar muestras de maltrato."
	  }`
	
	  let structDataLocalBusiness = `{
		"@context":"https://schema.org",
		"@type":"LocalBusiness",
		"url":"https://www.sears.com.mx/",
		"image": "https://www.sears.com.mx/img/logo-sears.svg",
		"brand": "SEARS",
		"name": "SEARS OPERADORA MEXICO, S.A. DE C.V.)",
		"legalName": "SEARS OPERADORA MEXICO, S.A. DE C.V.)",
		"slogan": "SEARS me entiende",
		"email": "sears.internet@sears.com.mx",
		"telephone": "(52) 55 54471407",
		"location": "Periferico Sur 4690, Jardines del Pedregal de San Ángel, Coyoacán, 04500 Ciudad de México, CDMX",
		"duns": "815026349",
		"hasPOS": "México",
		"priceRange": "$10 mxn a $500,000 mxn",
		"paymentAccepted": "Efectivo, Tarjeta de Crédito, Tarjeta SEARS",
		"openingHours": "Lunes a domingo 11am - 9pm",
		"logo": {
		  "@context": "https://schema.org",
		  "@type": "ImageObject",
		  "contentUrl" : "https://www.sears.com.mx/img/logo-sears.svg"
		},
		"geo": "19.303764325805826, -99.19016110505355",
		"latitude": "19.303764325805826",
		"longitude": "-99.19016110505355",
		"hasMap": "https://www.google.com/maps/place/Sears/@19.3035567,-99.1922747,17z/data=!3m2!4b1!5s0x85cdffd89dfb2a0b:0x73e875c3434af96c!4m5!3m4!1s0x85ce00762ad119b7:0xaef4ac17d00633e3!8m2!3d19.3035517!4d-99.190086",
		"address": {
		  "@type": "PostalAddress",
		  "addressLocality": "Ciudad de México, México",
		  "postalCode": "04500",
		  "streetAddress": "Periferico Sur 4690, Jardines del Pedregal de San Ángel, Coyoacán"
		},
		"foundingDate": "1947",
		"foundingLocation": "México",
		"parentOrganization": "Grupo Sanborns",
		"currenciesAccepted": "MXN",
		"areaServed": {
		  "@type": "State",
		  "name": "México"
		},
		"hasMerchantReturnPolicy": "Podrás devolver cualquier artículo comprado en Sears Internet por las siguientes causas: Si el artículo presenta defectos de fabricación, Si existe equivocación en el artículo enviado, conservando la envoltura original de celofán (emplaye) y sin presentar muestras de maltrato."
	  }`
	
	  let structDataWebSite = `{
		"@context":"https://schema.org",
		"@type":"WebSite",
		"url":"https://www.sears.com.mx/",
		"potentialAction":{
		  "@type":"SearchAction",
		  "target":"https://www.sears.com.mx/resultados/q={search_term_string}/pagina=1",
		  "query-input":"required name=search_term_string"
		}
	  }`
	
	return(
		<>
			{ loading && <Loading /> }
			<Helmet>
				<title>SEARS - Me entiende</title>
				<link rel="canonical" href="https://www.sears.com.mx/" />
				<meta name="Description" content="SEARS.COM.MX | Envíos a todo México · Más de 500 mil productos con todas las Formas de Pago  Compras 100% Seguras · Lo mejor en Moda, Línea blanca, Pantallas, Computo, Celulares, Juguetes, Muebles, Ferretería y más · Click & Recoge en Tienda" />

				<meta name="robots" content="index, follow" />

				<script type="application/ld+json">
					{ structDataOrganization }
				</script>
				<script type="application/ld+json">
					{ structDataLocalBusiness }
				</script>
				<script type="application/ld+json">
					{ structDataWebSite }
				</script>
			
			</Helmet>


			{isMobile === 'desktop'
				?
					<>
						<Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
						<section className="barCpResponsive">
							<BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>	
						</section>
						
						<HomeTemplateDesktopNew
							respHomeV3={respHomeV3}
							errors={errors}
							dlContent={dlContent}
						/>
						<DownloadApp section="home"></DownloadApp>
					</>
				:  
					<>
						<Header page='pageHome' setUpCP={setUpCP}/>
						<Link className="fakeSearch" to="/buscador">
							<span>¿Qué es lo que buscas?</span>
						</Link>
						<ErrorBoundary>
							<BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>
						</ErrorBoundary>

						<HomeTemplateMobileNew
							respHomeV3={respHomeV3}
							errors={errors}
							dlContent={dlContent}
						/>
						<DownloadApp section="home"></DownloadApp>

					</>
			}
		</>
	);
}

export default React.memo(Home)