import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MenuPrincipal from 'components/Menu';
import CartHeaderCount from 'components/Cart/CartHeaderCount';
import HeadeWishlist from 'components/CardProduct/CardWishlist/headerWishlist';
import { useStorage64 } from 'hooks/useStorageBase64';
import { ErrorBoundary } from 'components/Error/ErrorBoundary';
import { HeaderGenerico, HeaderMiCuentaSears } from 'components/Header/HeaderGenerico/headerGenerico';
import { HeaderWishlist } from 'components/Header/HeaderWishlist';
import { useHistory } from 'react-router-dom';
import { VARIANTS_PER_PORTAL } from 'constants/general'

import { HeaderBarMovile } from 'pages/Header/HeaderNavegacion/HeaderBarMovile'
import { useReturn } from 'hooks/useHistoryUrl'

export function HeaderMobile(props) {
    const {
        isLoged,
        profile,
        totalItems,
        page = null,
        headerEnCategoria,
        categoriaRecibida,
        categoriaPadre,
        tituloPagina,
        searchWork,
        claseMas
    } = props

    const history = useHistory();
    const [showModal, SetShowModal] = useState(false);
    const [dataFace] = useStorage64('dataFacebook', '');
    const [nombre0, setnombre0] = useState("");
    const hostName = window.location.origin;
    const urlLink = window.location.href;
    const isNativeApp = window.location.search.indexOf('isNativeApp=true')

   const {clickGoBack}  = useReturn({name: "asasd"})

    //- Funciones para activar o desactivar el modal del menu lateral
    const showMenu = () => {
        // SetShowModal(true);
        history.push("/menu-mas");
    }
    const handleClose = () => { SetShowModal(false); }

    let nombreArray = [];
    useEffect(() => {
        if (profile.nombre) {
            nombreArray = profile.nombre.split(' ');
            setnombre0(nombreArray[0]);
        }
    }, [profile])

    useEffect(() => {
        if (isLoged && dataFace.name) {
            nombreArray = dataFace.name.split(' ');
            setnombre0(nombreArray[0]);
        }
    }, [dataFace])

    const handleBack = () => {
        history.goBack();
    };

    const RegresarCategoria = () => {
        // history.goBack();
        clickGoBack()
    };

    const [namec, setName] = useState(false);
    useEffect(() => {
        if (categoriaRecibida) {
            setName(categoriaRecibida);
        }
    }, [categoriaRecibida])

    const [namep, setNamep] = useState(false);
    useEffect(() => {
        if (categoriaPadre) {
            setNamep(categoriaPadre);
        }
    }, [categoriaPadre])

    return (
        <>
            <>
                {(headerEnCategoria && headerEnCategoria === "headerEnCategoria")
                    ?
                    <header className={page + " paddingM"}>
                        <div className="headerMobile hcate">
                            <div className="regresarPadre" onClick={RegresarCategoria}></div>
                            <div className="headerTituloCategoria">
                                <p>Departamentos </p>
                            </div>
                            <ErrorBoundary>
                                <CartHeaderCount
                                    ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0}
                                />
                            </ErrorBoundary>

                            {isNativeApp < 0
                                ?
                                    <div className="menuPrincipal">
                                        <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                    </div>
                                : null
                            }
                        </div>
                    </header>
                    :
                    (headerEnCategoria && headerEnCategoria === "headerEnCategoriaHija")
                        ?
                        <header className={page + " paddingM"}>
                            <div className="headerMobile hcate">
                                <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                <div className="headerTituloCategoriaHija">
                                    {namep
                                        ? <p>{namep}</p>
                                        : <p>Departamentos</p>
                                    }
                                </div>
                                <ErrorBoundary>
                                    <CartHeaderCount ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0} />
                                </ErrorBoundary>

                                {isNativeApp < 0
                                    ?
                                        <div className="menuPrincipal">
                                            <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                        </div>
                                    : null
                                }

                            </div>
                        </header>
                        : (headerEnCategoria && headerEnCategoria === "headerEnCategoriaNieta")
                            ?
                            <header className={page + " paddingM"}>
                                <div className="headerMobile">
                                    <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                    <div className="headerTituloCategoriaHija">
                                        {namec
                                            ? <p>{namec}</p>
                                            : <p>Departamentos</p>
                                        }
                                    </div>
                                    <ErrorBoundary>
                                        <CartHeaderCount
                                            ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0}
                                        />
                                    </ErrorBoundary>

                                    {isNativeApp < 0
                                        ?
                                            <div className="menuPrincipal">
                                                <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                            </div>
                                        : null
                                    }

                                </div>
                            </header>
                            :
                            (headerEnCategoria && headerEnCategoria === "headerCarrito")
                                ?
                                <header className={page}>
                                    <div className="headerMobile">
                                        <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                        <div className="headerTituloCarrito">
                                            <p>Mi bolsa</p>
                                        </div>

                                        <ErrorBoundary>
                                            {page !== "pageHome" &&
                                                <>
                                                    <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador"></Link>
                                                </>
                                            }
                                        </ErrorBoundary>

                                        {isNativeApp < 0
                                            ?
                                                <div className="menuPrincipal">
                                                    <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                                </div>
                                            : null
                                        }
                                        
                                    </div>
                                </header>
                                : (headerEnCategoria && headerEnCategoria === "headerLocalizador")
                                    ? <header className={page}>
                                        <div className="headerMobile">

                                            <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                            <div className="headerTituloCarrito">
                                                <p>Localizador de Tiendas</p>
                                            </div>

                                            <div className="contBolsaLista">
                                                <ErrorBoundary>
                                                    <HeaderWishlist
                                                        ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                                    />
                                                </ErrorBoundary>
                                                <ErrorBoundary>
                                                    <CartHeaderCount
                                                        ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`}
                                                        cantidad={isLoged ? totalItems : 0}
                                                    />
                                                </ErrorBoundary>
                                            </div>

                                            {isNativeApp < 0
                                                ?
                                                    <div className="menuPrincipal">
                                                        <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </header>
                                    : (headerEnCategoria && headerEnCategoria === "headerListaDeseos")
                                        ? <header className={page}>
                                            <div className="headerMobile hmicuenta">

                                                <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                                <div className="headerTituloCarrito">
                                                    <p>{tituloPagina}</p>
                                                </div>

                                                <div className="contBolsaLista">
                                                    {!history.location.pathname.includes("/direcciones-envio") &&
                                                        !history.location.pathname.includes("/mis-formas-pago") &&
                                                        !history.location.pathname.includes("/mi-perfil") &&
                                                        String(history.location.pathname) !== "/mi-cuenta" &&
                                                        !history.location.pathname.includes("/mis-pedidos")
                                                        ? <>
                                                            <ErrorBoundary>
                                                                {page !== "pageHome" &&
                                                                    <>
                                                                        <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador"></Link>
                                                                    </>
                                                                }
                                                            </ErrorBoundary>
                                                            <ErrorBoundary>
                                                                <CartHeaderCount
                                                                    ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`}
                                                                    cantidad={isLoged ? totalItems : 0}
                                                                />
                                                            </ErrorBoundary>
                                                        </>
                                                        : <div style={{ width: "30px" }}></div>
                                                    }
                                                </div>

                                                {isNativeApp < 0
                                                    ?
                                                        <div className="menuPrincipal">
                                                            <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                                        </div>
                                                    : null
                                                }
                                            </div>
                                        </header>
                                        : (headerEnCategoria && headerEnCategoria === "headerBuscador")
                                            ? <header className={page}>
                                                <div className="headerMobile">

                                                    <div className="buscadorMo">
                                                        <Link className="fakeSearch fakeBuscador" to="/buscador">
                                                            {searchWork
                                                                ? <span><p className="textoS">{decodeURI(searchWork)}</p></span>
                                                                : <span>Buscar</span>
                                                            }
                                                        </Link>
                                                    </div>
                                                    <div className="contBolsaLista">
                                                        <ErrorBoundary>
                                                            <HeaderWishlist
                                                                ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                                            />
                                                        </ErrorBoundary>
                                                        <ErrorBoundary>
                                                            <CartHeaderCount
                                                                ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0}
                                                            />
                                                        </ErrorBoundary>
                                                    </div>

                                                    {isNativeApp < 0
                                                        ?
                                                            <div className="menuPrincipal">
                                                                <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                                            </div>
                                                        : null
                                                    }

                                                </div>
                                            </header>
                                            : (headerEnCategoria && headerEnCategoria === "modalAddCart")
                                                ? <>
                                                    <header className={page}>
                                                        <div className="headerMobile haddcart">
                                                            <div className="hl">
                                                                <div className="regresarPadre" onClick={RegresarCategoria}></div>
                                                            </div>

                                                            <div className="hr">
                                                                <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador">.</Link>

                                                                <ErrorBoundary>
                                                                    <HeaderWishlist
                                                                        ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                                                    />
                                                                </ErrorBoundary>
                                                            </div>
                                                        </div>
                                                    </header>
                                                </>
                                                : <header className={page}>
                                                    <div className="headerMobile">

                                                        <ErrorBoundary>
                                                            {page === "pageHome"
                                                                ? <>
                                                                    <div className="bxVacio">
                                                                        <Link to="/" className="logoClaroshop" aria-label="logoSears">.</Link>
                                                                    </div>
                                                                </>
                                                                : <>
                                                                    <div className="bxarrowLeft" >
                                                                        {/* <p className="arrowLeft"></p> */}
                                                                        {claseMas
                                                                            ? <Link to="/" className="logoClaroshop logoClaroshopMas" aria-label="logoSears">.</Link>
                                                                            : <Link to="/" className="logoClaroshop" aria-label="logoSears">.</Link>
                                                                        }
                                                                    </div>
                                                                    {/* onClick={handleBack} */}
                                                                </>
                                                            }

                                                            {(page !== "pageHome" && !claseMas) &&
                                                                <>
                                                                    <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador">.</Link>
                                                                </>
                                                            }
                                                        </ErrorBoundary>

                                                        {/* <ErrorBoundary>
                                                        {page === "pageHome" &&
                                                            <HeadeWishlist ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`} />
                                                        }
                                                    </ErrorBoundary> */}

                                                        <ErrorBoundary>
                                                            <HeaderWishlist
                                                                ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`}
                                                            />
                                                        </ErrorBoundary>
                                                        <ErrorBoundary>
                                                            <CartHeaderCount
                                                                ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`}
                                                                cantidad={isLoged ? totalItems : 0}
                                                            />
                                                        </ErrorBoundary>

                                                        {isNativeApp < 0
                                                        ?
                                                            <div className="menuPrincipal">
                                                                <HeaderBarMovile isLoged={isLoged} profile={profile} />
                                                            </div>
                                                        : null
                                                    }

                                                    </div>
                                                </header>
                }
            </>

            {((headerEnCategoria && headerEnCategoria === "headerEnCategoriaNieta")) &&
                <Link className="fakeSearch searchCate" to="/buscador">
                    <span>Buscar</span>
                </Link>
            }
            {(claseMas) &&
                <Link className="fakeSearch searchMas" to="/buscador">
                    <span>¿Qué es lo que buscas?</span>
                </Link>
            }

            {showModal && <MenuPrincipal onClose={handleClose} />}
        </>
    )
}